h2.ui.header {
  margin: 0 0 1rem;
}
.ProfileForm form {
  flex: 3;
}
.ProfileForm form {
  flex: 3;
}
.ProfileForm .ui.message {
  margin: 0 0 0 1rem;
  flex: 1;
}
.ProfileForm .ui.tab.active.flex-block {
  display: flex;
}
.ProfileForm .bottom-info-blockquote {
  position: absolute;
  bottom: 1rem;
  margin-right: 1rem;
}
.ProfileForm .bottom-info-blockquote cite{
  float: right;
  margin-right: 1rem;
}
.ProfileForm .ui.error.message {
  margin-top: 1rem;
  margin-left: 0;
}
.ProfileForm .ui.menu .ui.header .content {
  padding: 0 5px;
}
.ProfileForm .ui.item.menu .item,
.ProfileForm .ui.corner.label .icon {
  cursor: pointer;
}
@media only screen and (max-width: 850px) {
  .ProfileForm .message.info {
    display: none;
  }
  .ProfileForm .message.info.absolute-block{
    display: block;
    position: absolute;
    right: 1rem;
    width: 190px;
    height: calc(100% - 2rem);
  }
}
@media only screen and (max-width: 540px) {
  .ProfileForm .ui.pointing.menu {
    flex-direction: column;
  }
  .ProfileForm .ui.menu.four.item .item {
    width: 100%;
    padding-left: 1rem !important;
    justify-content: right;
  }
  .ProfileForm .ui.item.menu .item:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  .UserStatistic .ui.grid > [class*="four wide"].column {
    width: 100%!important;
  }
}
