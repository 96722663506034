.CreateEventForm {
    width: 100%;
}
.CreateEventForm .ui.small.image {
    margin-bottom: 1rem;
}
.CreateEventForm .ui.table td .image,
.CreateEventForm .ui.table td .image img {
    max-width: 150px;
}
.CreateEventForm button.submit.left {
    margin-left: auto;
    margin-right: 0px;
    margin-top: 1rem;
    display: block;
}
.CreateEventForm .field label {
    min-width: 150px;
}
.CreateEventForm .flex-column {
    display: flex;
    flex-direction: column;
}
.CreateEventForm .flex-column.padding-between:not(:first-child) {
    padding-top: 10px;
}
.CreateEventForm label.label-on-top {
    align-self: self-start;
    padding-top: 10px;
}
.CreateEventForm .custom-disabled div:not(:first-child),
.CreateEventForm .custom-disabled label.ui {
    pointer-events: none;
    opacity: 0.45;
}
.CreateEventForm .custom-disabled.disabled-background {
    background-color: rgba(211, 211, 211, 0.2);
}
.CreateEventForm .ui .field.description-checkbox {
    margin-top: 1rem;
    display: flex;
}
.CreateEventForm .description-checkbox label,
.CreateEventForm .description-checkbox .Checkbox {
    flex: 1;
}
.CreateEventForm .description-checkbox .red {
    color: red;
}
.CreateEventForm .tabular .item.disabled{
    pointer-events: none;
    opacity: 0.45;
    background-color: lightgray;
}
.CreateEventForm .datepicker,
.CreateEventForm .datepicker > div,
.CreateEventForm .datepicker .react-datepicker-wrapper,
.CreateEventForm .datepicker .react-datepicker__input-container,
.CreateEventForm .ui.form .field .ui.input.datepicker input {
    width: 100%;
}
.CreateEventForm .datepicker ul {
    padding-left: 0;
}
.CreateEventForm .datepicker .react-datepicker-popper {
    width: 285px;
}
@media only screen and (max-width: 1150px) {
    .CreateEventForm .ui.two.column.grid {
        flex-direction: column;
    }
    .CreateEventForm .ui[class*="two column"].grid > .column:not(.row) {
        width: 100%;
    }
    .CreateEventForm .Checkbox {
        align-self: flex-start;
    }
}
