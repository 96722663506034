.AdminOrderReports  {
  width: 100%;
  height: 100%;
}
.AdminOrderReports .ui.styled.accordion {
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  height: 100%;
}
.AdminOrderReports .ui.styled.accordion .title {
  background: #fff;
  margin-bottom: 1rem;
}
.AdminOrderReports .ui.styled.accordion .content.active {
  height: calc(100% - 100px);
}
.AdminOrderReports .vacation-colored-block {
  flex: 1;
  background-color: orange;
  padding: 1rem 0.5rem;
}
.AdminOrderReports .sick-colored-block {
  flex: 1;
  background-color: rgb(111, 142, 187);
  padding: 1rem 0.5rem;
  cursor: pointer;
}
.AdminOrderReports .ui.header .colored-container {
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
}

.AdminOrderReports .flex-container {
  display: flex;
  width: 100%;
  background: #FFFFFF;
  position: relative;
  margin: 1em 0em;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, .25);
}
.AdminOrderReports .flex-container .fixed-block {
  display: flex;
  flex-direction: column;
}
.AdminOrderReports .scrollable-block {
  display: flex;
  overflow: auto;
  width: 100%;
  position: absolute;
}
.AdminOrderReports .flex-container .ui.header {
  margin: 0;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  height: 64px;
}
.ui.styled.accordion .AdminOrderReports .flex-container .content {
  padding: 1rem;
}
.ui.styled.accordion .AdminOrderReports .flex-container .ui.header:first-child .content {
  display: flex;
  align-items: center;
  height: 100%;
}
.AdminOrderReports .flex-container .ui.header:first-child {
  border-top: 0;
  background: #F9FAFB;
  height: 85px;
}


.AdminOrderReports .text-center {
  text-align: center;
}
.AdminOrderReports .workday,
.AdminOrderReports .weekend {
  width: 100%;
  height: 100%;
}
.AdminOrderReports .flex-container .ui.header.weekend:first-child,
.AdminOrderReports .weekend {
  background: rgba(255, 0, 0, 0.13);
}
.AdminOrderReports .flex-container .ui.header.today:first-child,
.AdminOrderReports .today {
  background: rgb(175, 175, 175);
}
.AdminOrderReports .card.card-with-table {
  height: 100%;
  display: flex;
  width: 100%;
  padding-bottom: 1.2rem;
  min-height: 400px;
}
.AdminOrderReports .card.grid {
  height: 100%;
  width: 100%;
}
.AdminOrderReports a.ui.label {
  cursor: pointer;
  background-color: #fffaf3;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
  color: #573a08;
}
.AdminOrderReports .label:before{
  box-shadow: 2px 2px 0px -1px #c9ba9b inset, 0 0 0 0 transparent;
}
.AdminOrderReports .ui[class*="top attached"].segment {
  margin-top: 0;
}
.AdminOrderReports a.ui.label:hover {
  background-color: #fff6f2;
  color: rgba(0, 0, 0, 0.8);
}
.AdminOrderReports .ui.icon.input > i.icon {
  opacity: 1;
}
.AdminOrderReports .selected.month{
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
  border-radius: 3px;
  background-color: #e4ffc7;
}
.AdminOrderReports .month{
  padding: 0 5px;
  margin: 0 -5px;
}
.AdminOrderReports .month:hover{
  border-radius: 3px;
  background-color: #e4ffc7;
  cursor: pointer;
}
.AdminOrderReports .card.card-with-table {
  height: calc(100% - 80px);
  display: flex;
  width: 100%;
  padding-bottom: 1.1rem;
}
.AdminOrderReports .html.grid {
  height: 100%;
}
.AdminOrderReports .monthCard.ui.card {
  width: auto;
}
.AdminOrderReports .ui.checkbox {
  padding-top: 1rem;
}

.AdminOrderReports input {
    /*margin-top: 1em;*/
    -webkit-appearance: none;
    padding: 0.78571429em 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    -o-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-size: 1em;
    width: 100%;
    line-height: 1.2857;
}

.AdminOrderReports .kv-row {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminOrderReports .kv-row:after {
  content: none !important;
}

.AdminOrderReports .download-button {
  border: 1px solid hsl(210deg 6% 14% / 10%);
  padding: 1em 1em;
  width: fit-content;
  font: 1em Montserrat;
  font-weight: 700;
  color: hsl(0deg 0% 35%);
  border-radius: 4px;
  cursor: pointer;
  transition: background .4s linear 0s;
}

.AdminOrderReports .download-button:hover {
  background: hsl(210deg 4% 50% / 20%);
}