.OrderHistory {
    width: 100%;
    height: 100%;
}
.OrderHistory .ui.header:first-child.divider {
    margin-top: 3rem;
}

.OrderHistory .ui.message:last-child {
    margin-bottom: 2em;
}
.OrderHistory .column.row  {
    margin-left: 20px;
    margin-bottom: 20px;
}
.OrderHistory .ui.message {
    margin-left: 20px;
}
.OrderHistory .ui.list  {
    margin: 0;
}
.OrderHistory .column.grid.right  {
    justify-content: flex-end;
}
.OrderHistory .ui.grid > .row:not(:first-child) {
    padding-top: 0;
}
.OrderHistory .item.flex {
    display: flex;
}
.OrderHistory .item.flex.header {
    font-size: 18px;
    padding: 1em 0;
}
.OrderHistory .width25 {
    width: 25%;
}
.OrderHistory .width50 {
    width: 50%;
}
.OrderHistory .ui[class*="top attached"].segment {
    margin-top: 0;
    height: 100%;
    overflow-y: auto;
}
.OrderHistory .selected.month{
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    border-radius: 3px;
    background-color: #e4ffc7;
}
.OrderHistory .month{
    padding: 0 5px;
    margin: 0 -5px;
}
.OrderHistory .month:hover{
    border-radius: 3px;
    background-color: #e4ffc7;
    cursor: pointer;
}
.OrderHistory .ui.message .header:not(.ui) {
    font-size: 1.5rem;
}
.OrderHistory .ui.message .header:not(.ui) div:first-child {
    padding-bottom: 10px;
}
.OrderHistory .ui.message .header:not(.ui) div:not(:first-child) {
    font-size: 1.2rem;
    color: #000;
}
.OrderHistory .ui.ribbon.label {
    top: -8px;
}
.OrderHistory .ui.list > .item {
    padding-top: 7px;
}
@media only screen and (max-width: 450px) {
    .OrderHistory .column.row,
    .OrderHistory .ui.message {
        margin-left: 0;
    }
    .OrderHistory .ui.ribbon.label {
        left: -1.2em;
    }
    .OrderHistory .ui.ribbon.label:after {
        border-width: 0 0.55rem 0.6rem 0;
    }
    .OrderHistory .ui.raised.segment .ui.row.wide {
        overflow: auto;
        display: block;
    }
    .OrderHistory .ui.raised.segment .ui.row.wide .column {
        min-width: 380px;
    }
}
