.EventTypesSelector .search-cross-icon{
    position: absolute;
    z-index: 15;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    right: 2rem;
    opacity: 0.8;
}
.EventTypesSelector {
    width: 100%;
}
.EventTypesSelector .ui.selection {
    width: 100%;
}