@import "../../pages/Handbook/handbook-contacts/index.scss";

$button-color: #21BA45;
$button-hover-color: #07a505;

.ui-green-button {
  width: 30px;
  height: 35px;
  background: $button-color;
  border: none;
  border-radius: 4px;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    background-color: $button-hover-color;
  }
}

.icon.button.add {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: auto !important;
  color: white;
}

.styleForEditList{
  border: 0;
}