.Vacation .ui.vertical.steps.left .step:after {
  right: 100%;
  border-width: 1px 0px 0px 1px;

}
.Vacation h2.ui.header {
  margin: 0 0 1rem;
}
.Vacation ul.ui.list.green li:before {
  color: green;
}
.Vacation .search-input{
  display: flex;
  flex: 1;
  padding-left: 1rem;
  height: 2.75rem;
}
.Vacation .ui.table th .image,
.Vacation .ui.table th .image img,
.Vacation .ui.table td .image,
.Vacation .ui.table td .image img{
  max-width: 150px;
}
.Vacation .padding-for-search-block {
  padding-top: 62px;
}

.Vacation .color-example {
  margin-left: 1rem;
  width: 30px;
}

@media only screen and (max-width: 850px) {
  .Vacation .ui.mobile-side-menu {
    flex-direction: column;
    display: block;
  }
  .Vacation .mobile-side-menu .PageSidePanel {
    background: #FFF;
  }
  .Vacation .SideColumnWithMobileBottomLine .ui.modal,
  .Vacation .SideColumnWithMobileBottomLine .ui.modal .image.content {
    background: none;
  }
}
@media only screen and (max-width: 530px) {
  .Vacation .for-admin.padding-for-search-block {
    padding-top: 105px;
  }
  .Vacation .search-block {
    width: 100%;
    display: block;
  }
  .Vacation .search-block .UsersSelector{
    padding: 0;
  }
  .Vacation .search-block .search-input {
    width: 100%;
  }
  .Vacation .search-block .for-admin.search-input{
    margin-top: 1rem;
  }
  .Vacation .search-block .UsersSelector .ui.search.dropdown{
    width: 100%;
  }
}
