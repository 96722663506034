.time-message .short-sub-message {
    display: none;
}
@media only screen and (max-width: 1180px) {
    .time-message .short-sub-message {
        display: inline;
    }
    .time-message .full-sub-message {
        display: none;
    }
}
