.Request .ui.vertical.steps.left .step:after {
  right: 100%;
  border-width: 1px 0px 0px 1px;

}
.Request h2.ui.header {
  margin: 0 0 1rem;
}
.Request ul.ui.list.green li:before {
  color: green;
}
.Request .search-input{
  display: flex;
  flex: 1;
  height: 2.75rem;
}
.Request .user-selector{
  padding-right: 1rem;
  display: flex;
}
.Request .ui.table th .image img,
.Request .ui.table td .image img{
  max-width: 150px;
}
.Request .padding-for-search-block {
  padding-top: 40px;
}
@media only screen and (max-width: 850px) {
  .Request .ui.mobile-side-menu {
    flex-direction: column;
    display: block;
  }
  .Request .mobile-side-menu .PageSidePanel {
    background: #FFF;
  }
}
@media only screen and (max-width: 460px) {
  .Request .for-admin.padding-for-search-block {
    padding-top: 95px;
  }
}
