.SideColumnWithMobileBottomLine {
    width: 25%;
    padding-top: 1rem;
}
.SideColumnWithMobileBottomLine.six {
    width: 37.5%;
}
.SideColumnWithMobileBottomLine {
    display: none;
}
@media only screen and (max-width: 850px) {
    .SideColumnWithMobileBottomLine {
        display: flex;
        width: 100%;
    }
    .ui.grid .SideColumnWithMobileBottomLine [class*="four wide"].column.modal-type {
        display: flex;
    }
    .SideColumnWithMobileBottomLine .modal-type {
        position: absolute;
        width: 100%;
        align-items: center;
        height: 100%;
        justify-content: center;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
    }
    .SideColumnWithMobileBottomLine .ui.modal > .content {
        padding: 0!important;
    }
    .SideColumnWithMobileBottomLine .ui.modal .extra.content > .description {
        padding: 0 !important;
    }
    .SideColumnWithMobileBottomLine .ui.modal .extra.content > .flex-block.description {
        display: flex;
    }
    .SideColumnWithMobileBottomLine .bottom-line-sub-menu {
        display: flex;
        position: fixed;
        bottom: -9px;
        left: 0;
        align-items: flex-start;
        background: rgba(0, 0, 0, 0.8);
        height: 60px;
        width: 100%;
        z-index: 10;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .SideColumnWithMobileBottomLine .bottom-line-sub-menu.iPhone-styles {
        height: 80px;
    }
    .SideColumnWithMobileBottomLine .bottom-line-sub-menu_button {
        background: #FFF;
        border: 1px solid #FFF;
        border-radius: 20px;
        color: #2F2F2F;
        padding: 5px 15px;
        margin: 10px 0 10px 15px;
        white-space: nowrap;
        box-shadow: inset 0 2px 10px rgba(0, 0, 0, .4);
    }
    .SideColumnWithMobileBottomLine .bottom-line-sub-menu_button.active {
        background: #21BA62;
        border: 1px solid #21BA62;
        color: #FFF;
        box-shadow: inset 0 5px 10px rgba(0, 128, 26, .7);
    }
    .SideColumnWithMobileBottomLine .bottom-line-sub-menu_empty-element {
        border: 0;
        width: 0;
        height: 0;
        margin-left: 15px;
        padding: 1px 1px;
        background: rgba(0, 0, 0, 0.8);
    }
}
