.VacationsList {

}
.VacationsList .ui.green.button:hover,
.VacationsList .ui.green.button:active {
    background-color: #21BA62;
}
.VacationsList .ui.orange.button:hover,
.VacationsList .ui.orange.button:active {
    background-color: #F2711C;
}
.VacationsList .ui.red.button:hover,
.VacationsList .ui.red.button:active {
    background-color: #DB2828;
}
.VacationsList .ui.segments > .horizontal.segments {
    background-color: #FFF;
}
.VacationsList .vacation-card .ui.pseudo-segment,
.VacationsList .vacation-card .ui.segment {
    padding: 1rem 1.2rem;;
}
.VacationsList .ui.segments.vacation-card {
    margin-top: 1rem;
}
.VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-info,
.VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-header {
    display: none;
}
.VacationsList .vacation-card .vacation-card_header {}
.VacationsList .vacation-card .vacation-card_header .vacation-card_header_number {
    display: flex;
    align-items: center;
}
.VacationsList .vacation-card .vacation-card_header .vacation-card_header_owner {
    align-items: center;
    display: flex;
}
.VacationsList .vacation-card .vacation-card_comment {
    word-break: break-word;
    white-space: pre-line;
}
.VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-header_durations {
    display: none;
}
.VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-header_durations .durations_end-time,
.VacationsList .ui.segments.vacation-card .vacation-card_sub-header_durations .durations_end-time {
    margin-left: 20px;
}
.VacationsList .ui.segments.vacation-card .vacation-card_sub-header .vacation-card_sub-header_days {
    display: flex;
    justify-content: center;
}

.VacationsList .ui.segments.vacation-card .vacation-card_sub-header .vacation-card_sub-header_days.green {
    color: #000;
    background: rgba(0, 128, 26, 0.3);
    border-top: none!important;
}
.VacationsList .ui.segments.vacation-card .vacation-card_sub-header .vacation-card_sub-header_days.yellow {
    color: #000;
    background: rgba(248, 255, 34, 0.2);
    border-top: none!important;
}
.VacationsList .ui.segments.vacation-card .vacation-card_sub-header .vacation-card_sub-header_days.blue {
    color: #000;
    background: rgba(34, 10, 155, 0.2);
    border-top: none!important;
}
.VacationsList .edit-vacation-modal_date-pickers .start-time {
    display: inline-block;
}
.VacationsList .edit-vacation-modal_date-pickers .end-time {
    display: inline-block;
    margin-left: 2rem;
}
.VacationsList .vacation-card .vacation-card_header {}
.VacationsList .vacation-card .vacation-card_header .vacation-card_header_number {
    display: flex;
    align-items: center;
}
.VacationsList .vacation-card .vacation-card_header .vacation-card_header_title {
    align-items: center;
    display: flex;
}
.VacationsList .vacation-card .vacation-card_header .vacation-card_header_buttons {
    display: flex;
    align-items: center;
    flex: none;
}
.VacationsList .vacation-card .vacation-card_header .vacation-card_header_buttons .status {
    cursor: default;
    border-radius: 20px;
    color: #FFF;
    margin-left: 0.7rem;
}
.VacationsList .vacation-card .vacation-card_header .vacation-card_header_buttons .status .icon {
    color: #FFF;
}
.VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-header_durations .vacation-card_leave,
.VacationsList .ui.segments.vacation-card .vacation-card_sub-header_durations .vacation-card_leave {
    margin-left: 20px;
    font-weight: 700;
}

.VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-header_durations .vacation-card_leave--unpaid,
.VacationsList .ui.segments.vacation-card .vacation-card_sub-header_durations .vacation-card_leave--unpaid {
    color: #F2711C;
}

.VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-header_durations .vacation-card_leave--paid,
.VacationsList .ui.segments.vacation-card .vacation-card_sub-header_durations .vacation-card_leave--paid {
    color: #21BA45;
}

@media only screen and (max-width: 650px) {
    .VacationsList .edit-vacation-modal_date-pickers .start-time,
    .VacationsList .edit-vacation-modal_date-pickers .end-time {
        display: block;
        margin-left: 0;
    }
}
@media only screen and (max-width: 500px) {
    .VacationsList .vacation-card .vacation-card_header .vacation-card_header_buttons .status.only-icon-for-small-width span {
        display: none;
    }
    .VacationsList .vacation-card .vacation-card_header .vacation-card_header_buttons .status.only-icon-for-small-width i {
        margin: 0;
    }
    .VacationsList .vacation-card .ui.pseudo-segment.vacation-card_header_buttons {
        padding: 0.7rem;
    }
    .VacationsList .ui.segments.vacation-card .vacation-card_sub-header_durations .vacation-card_leave,
    .VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-header_durations .vacation-card_leave {
        margin-left: 0;
        margin-top: 8px;
        display: block;
    }
}
@media only screen and (max-width: 390px) {
    .VacationsList .ui.segments.vacation-card .vacation-card_mobile-sub-header_durations {
        display: flex;
        flex-wrap: wrap;
    }
    .VacationsList .ui.segments.vacation-card .vacation-card_sub-header_durations {
        display: none;
    }
}

