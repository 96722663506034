.Menu {
    width: 100%;
    text-align: center;
}
.Menu .ui.header:first-child.divider {
    margin-top: 3rem;
}
.Menu div.add-dish {
    position: absolute;
    right: 50px;
    left: auto;
    z-index: 10;
    top: 30px;
}
.Menu .category-name {
    padding: 10px;
    margin: 0;
}
.Menu .menu-category_header {
    padding: 1rem;
    border-bottom: 1px solid rgba(34,36,38,.1);
}

.Menu .menu-category_body{
    overflow:auto;
}

.Menu .menu-category_body_row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(34,36,38,.1);
    min-width:500px;
}
.Menu .menu-category_body_counter {
    align-items: center;
    width: auto;
    display: flex;
}
.Menu .menu-category_body_counter i {
    font-size: 1.7rem;
    height: 19px;
}
.Menu .menu-category_body_row.positive {
    background: #F8F8F8;
}
.Menu .menu-category_body_row:not(.positive):hover {
    background: #F8F8F8;
}
.Menu .menu-category .ten-percent {
    padding: 1rem;
    width: 10%;
    min-width: 85px;
}
.Menu .menu-category .celled.flexible {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.Menu .menu-category .celled.flexible h4 {
    margin-bottom: 0;
}
.Menu .menu-category .celled.flexible input[type="number"] {
    width: 40px;
    height: 20px;
    align-self: center;
}
.Menu .menu-category .menu-category_body_checkbox-wrapper {
    margin-left: 10px;
    padding: 1rem 0 1rem 1rem;
}
.Menu .menu-category .celled {
    flex: 1;
    padding: 1rem;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
}
.Menu .menu-category.disabled {
    pointer-events: none;
    opacity: 0.45;
}
.Menu .menu-category .ui.header .sub.header {
    padding-top: 5px;
    word-break: break-word;
}
.Menu .message.add-dish {
    margin: 0 6.4em 2em 2em;
}
.Menu .column .floated.right {
    float: right;
}
.Menu .column .floated.left {
    float: left;
}
.Menu .card.card-with-table {
    display: flex;
    width: 100%;
    padding-bottom: 1.1rem;
}
.Menu .ui.attached.segment {
    border-bottom: 0;
}
.Menu .ui[class*="top attached"].segment {
    margin-top: 0;
}
.Menu .ui.html.segment.grid {
    overflow: auto;
}
.Menu .ui.card.card-with-table:last-child {
    margin-bottom: 1rem;
}
.Menu .ui.message:last-child {
    margin-bottom: 0;
}
.Menu .ui.grid > .column:not(.row).time-message {
    padding-bottom: 0;
}
.Menu .menu-category_body_row.custom-disabled {
    pointer-events: none;
    opacity: 0.45;
}
.Menu .custom-disabled.disabled-background {
    background-color: rgba(211, 211, 211, 0.2);
}
.Menu .ui.cards > .card > :last-child,
.Menu .ui.card > :last-child,
.Menu .ui.table thead tr:first-child > th:only-child,
.Menu .ui[class*="bottom attached"].buttons .button:last-child,
.Menu .ui[class*="bottom attached"].buttons .button:first-child {
    border-radius: 0;
}
.Menu .ui.table thead th {
    background: #FFF;
}
.Menu .ui.card > .buttons {
    margin: 0;
}


.Menu .dish-rating{
    border-left: 1px solid rgba(34, 36, 38, 0.1);
    align-self: stretch;
    width:50px;
    min-width:50px;

}
.Menu .dish-rating div{
    align-items: center;
    justify-content:center;
    text-align:center;
    display:flex;
    height:100%
}
