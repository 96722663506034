.handbook-banner {
	display: grid;
	grid-template-columns: 564px auto;

	// flex-direction: row;
	.handbook-banner__section_left {
		height: 612px;
		background: #141f25;
		padding: 88px 49px 0px 56px;
		.handbook-banner__title {
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 40px;
			line-height: 120%;
			color: #ffffff;
			margin-bottom: 32px;
		}
		.handbook-banner__text {
			font-family: Montserrat;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 150%;
			color: #ffffff;
		}
	}
	.handbook-banner__section_image {
		height: 612px;
		background-size: cover;
		background-repeat: no-repeat;
		&.handbook-banner__section_image {
			background-image: url("./images/handbook-banner-dunice.jpg");
			background-position: center right;
		}
		//&.handbook-banner__section_image_italyan {
		//	background-image: url("./images/handbook-banner-italyan.jpg");
		//	background-position: center right;
		//}
		//&.handbook-banner__section_image_academy {
		//	background-image: url("./images/handbook-banner-academy.jpg");
		//	background-position: top right;
		//}
	}
}

@media (max-width: 1280px) {
	.handbook-banner {
		display: flex !important;
		flex-direction: column !important;
		.handbook-banner__section_left {
			height: auto !important;
			padding: 56px !important;
			.handbook-banner__title {
				font-size: 30px !important;
			}
			.handbook-banner__text {}
		}
		.handbook-banner__section_image {
			height: 400px !important;
			display: none !important;
		}
	}
}

@media (max-width: 750px) {
}

@media (max-width: 480px) {
    .handbook-banner {
        .handbook-banner__section_left {
            padding: 0 !important;
            color: #3b3b3c !important;
            * {
                color: #3b3b3c !important;
            }
            background-color: unset !important;
        }
    }
}
