.PeopleGame{
    min-height:400px;
    background-color:white;
    width: 100%;
    overflow: hidden;
}

.PeopleGame .startScreen button {
    width: 200px;
    height:60px;
    background-color:#1B1C1D;
    color:rgba(255, 255, 255, 0.7);
    font-size: 22px;
}

.PeopleGame .startScreen button:hover{
    cursor:pointer;
    color:white;
}

.PeopleGame .playScreen {
    background-color:#1B1C1D;
    height: 500px;
    color:white;
    padding:60px;
}

.PeopleGame .playScreen .question {
    min-height:26px;
    height:26px;
    border:1px solid white;
    position:relative;
    line-height: 24px;
}

.PeopleGame  .sides-right:after{
    position:absolute;
    top:-1px;
    right:-18px;
    display:block;
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 0 13px 17px;
    border-color: transparent transparent transparent white;
    z-index:9;
}
.PeopleGame  .sides-right:before{
    position:absolute;
    top: 0px;
    right: -17px;
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 17px;
    border-color: transparent transparent transparent #1B1C1D;
    z-index: 10;
}

.PeopleGame  .sides-left:after{
    position:absolute;
    top:-1px;
    left:-18px;
    display:block;
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 17px 13px 0px;
    border-color: transparent white transparent  transparent;
    z-index:9;
}
.PeopleGame  .sides-left:before{
    position:absolute;
    top: 0px;
    left: -17px;
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 17px 12px 0px;
    border-color: transparent #1B1C1D transparent transparent ;
    z-index: 10;
}

.PeopleGame .answers {
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
}

.PeopleGame .answers> div:not(.innerline){
    width:40%;
    min-height:26px;
    height:26px;
    border:1px solid white;
    position:relative;
    margin-top:10px;
    position:relative;
    cursor:pointer;
    line-height: 24px;
    white-space: nowrap;

}
.PeopleGame .answers .innerline{
    width: 20%;
    background-color: white;
    height: 1px;
    margin-top: 23px;
}

.PeopleGame .answers .first:before, .PeopleGame .answers .third:before, .PeopleGame .question:before{
    display: block;
    position: absolute;
    content: '';
    background-color: white;
    height: 1px;
    width: 30px;
    top: 12px;
    left: -47px;
}

.PeopleGame .answers .second:after, .PeopleGame .answers .fourth:after,.PeopleGame .question:after{
    display: block;
    position: absolute;
    content: '';
    background-color: white;
    height: 1px;
    width: 30px;
    top: 12px;
    right: -47px;
}

@media (max-width:1024px){
    .PeopleGame{
        font-size: 13px;
    }
    .PeopleGame .answers .innerline {
        width: 10%;
    }

    .PeopleGame .answers> div:not(.innerline){
        width:45%;
    }
}


@media (max-width:576px){
    .PeopleGame{
        font-size: 12px;
    }
    .PeopleGame .playScreen{
        padding:16px;
    }
    .PeopleGame .answers> div:not(.innerline){
        width:100%
    }
    .PeopleGame .answers .innerline{
        display:none;
    }
    .PeopleGame .answers .first:not(.fake){
        margin-top:15px;
    }

    .PeopleGame .answers .second:before, .PeopleGame .answers .fourth:before{
        display: block;
        position: absolute;
        content: '';
        background-color: white;
        height: 1px;
        width: 30px;
        top: 12px;
        left: -47px;
    }

    .PeopleGame .answers .first:after, .PeopleGame .answers .third:after
    {
        display: block;
        position: absolute;
        content: '';
        background-color: white;
        height: 1px;
        width: 30px;
        top: 12px;
        right: -47px;
    }
}

@media (max-width:350px){
    .PeopleGame{
        font-size: 11px;
    }
    .PeopleGame .playScreen{
        padding:16px 0px;
        height:450px;
    }
}

.PeopleGame .answers.should-answer> div:not(.innerline):hover{
    background-color:orange;
}
.PeopleGame .answers.should-answer> div:not(.innerline):hover .sides-right:before{
    border-color:transparent transparent transparent orange;
}
.PeopleGame .answers.should-answer> div:not(.innerline):hover .sides-left:before{
    border-color:transparent orange transparent transparent;
}

.PeopleGame .answers> div:not(.innerline).answered{
    background-color:orange;
}

.PeopleGame .answers> div:not(.innerline).answered .sides-right:before{
    border-color:transparent transparent transparent orange;
}
.PeopleGame .answers> div:not(.innerline).answered .sides-left:before{
    border-color:transparent orange transparent transparent;
}

.PeopleGame .answers.end> div:not(.innerline).correct{
    background-color:green;
}

.PeopleGame .answers.end> div:not(.innerline).correct .sides-right:before{
    border-color:transparent transparent transparent green;
}
.PeopleGame .answers.end> div:not(.innerline).correct .sides-left:before{
    border-color:transparent green transparent transparent;
}

.PeopleGame .answers.end> div:not(.innerline).answered:not(.correct){
    background-color:red;
}

.PeopleGame .answers.end> div:not(.innerline).answered:not(.correct) .sides-right:before{
    border-color:transparent transparent transparent red;
}
.PeopleGame .answers.end> div:not(.innerline).answered:not(.correct) .sides-left:before{
    border-color:transparent red transparent transparent;
}

.PeopleGame .pick-name, .PeopleGame .empty-box{
    height:150px;
    width:150px;
    background-color:transparent;
    margin:20px auto;
}


.PeopleGame #pick-name{
    padding:0px;
}

.PeopleGame .play-again{
    background-color: white;
    color: black;
    width: 200px;
    height: 30px;
    padding: 0px;
    border: 0px solid transparent;
    margin: 15px;
    cursor: pointer;
}
.PeopleGame .play-again:hover {
    background-color: lightgray;
}

.PeopleGame .come-tomorrow {
    margin-top:15px
}

.PeopleGame .pick-photo{
    display:flex;
    flex-wrap:wrap;
    justify-content:center
}
.PeopleGame #pick-photo-0,.PeopleGame #pick-photo-1,.PeopleGame #pick-photo-2,.PeopleGame #pick-photo-3{
    width:50%;
    padding:5px;
    position:relative;
}
.PeopleGame .letter{
    position:absolute;
    left:10px;
    top:10px;
}

.PeopleGame #pick-photo-0 img,.PeopleGame #pick-photo-1 img,.PeopleGame #pick-photo-2 img,.PeopleGame #pick-photo-3 img{
    padding:0px;
    width:50%;
    max-width: 110px;
}