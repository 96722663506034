.AdminRequestsList .content .sub.header {
    word-break: break-word;
    white-space: pre-line;
}

.AdminRequestsList textarea {
    margin-top: 1em;
    -webkit-appearance: none;
    padding: 0.78571429em 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    -o-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-size: 1em;
    width: 100%;
    line-height: 1.2857;
}
.AdminRequestsList .full-width{
    width: 100%;
}
.AdminRequestsList .all-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media only screen and (max-width: 400px) {
    .AdminRequestsList .ui.modal .actions > .button {
        width: 100%;
        margin-left: 0;
    }
}
