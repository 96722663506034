.EditSickModal .modal input,
.EditSickModal .modal textarea {
    margin-top: 1em;
    -webkit-appearance: none;
    padding: 0.78571429em 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    -o-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-size: 1em;
    width: 100%;
    line-height: 1.2857;
}
.EditSickModal .full-width {
    width: 100%;
}
.EditSickModal .modal label {
    font-size: 1.1rem;
    font-weight: bold;
    padding-right: 1rem;
}
.EditSickModal .edit-sick-modal_date-pickers .start-time {
    display: inline-block;
}
.EditSickModal .edit-sick-modal_date-pickers .end-time {
    display: inline-block;
    margin-left: 2rem;
}
.ui.styled.accordion .EditSickModal .full-width .content {
    padding: 0;
}
.ui.styled.accordion .EditSickModal .ui.modal > .content {
    padding: 1.5rem;
}
