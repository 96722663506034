.EventView {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;
    width: 100%;
}
.EventView .field .title {
    font-weight: bold;
    padding: 5px;
}
.EventView .header-title {
    margin-top: 1rem;
}
.EventView .scrolling-block {
    max-height: 50vh;
    overflow-x: auto;
}
.EventView .field .text-padding {
    padding-left: 20px;
}
.EventView .ui.message:first-child {
    margin-top: 2rem;
}
.EventView .sub-info-title {
    font-weight: bold;
    flex: 4;
    display: flex;
    margin-right: 1rem;
    white-space: nowrap;
}
.EventView .field.description:not(:first-child) {
    margin-top: 1rem;
}
.EventView .sub-info-text {
    flex: 7;
}
.EventView .absolute-top-right {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    z-index: 2;
}
.EventView .ui.label.absolute-top-right > .icon {
    margin: 0.1em 0.1em 0em 0em;
}
.EventView .ui.cards > .card > :last-child.absolute-top-right {
    border-radius: 500rem!important;
    margin: 0;
}
.EventView .segment.clearing .custom-floated.right {
    cursor: pointer;
}
@media only screen and (max-width: 1000px) {
    .EventView .ui.column.grid {
        flex-direction: column;
    }
    .EventView .ui.grid > [class*="five wide"].column,
    .EventView .ui.column.grid > [class*="five wide"].column,
    .EventView .ui.grid > [class*="eleven wide"].column,
    .EventView .ui.column.grid > [class*="eleven wide"].column {
        width: 100%!important;
    }
    .EventView .ui.three.cards > .card {
        width: calc(25% - 2em);
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .EventView .ui.three.doubling.cards > .card {
        width: calc(25% - 2em);
    }
}
@media only screen and (max-width: 767px) {
    .EventView .ui.three.doubling.cards > .card {
        width: calc( 33.33333333% - 2em );
    }
}
@media only screen and (max-width: 700px) {
    .EventView .ui.two.column.grid {
        flex-direction: column;
    }
    .EventView .ui[class*="two column"].grid > .column:not(.row) {
        width: 100%;
    }
}
@media only screen and (max-width: 467px) {
    .EventView .ui.three.doubling.cards > .card {
        width: calc( 50% - 2em );
    }
}
