.MenuOrderCard .description {
    text-align: left;
}
.MenuOrderCard .right {
    float: right;
}
.MenuOrderCard .green {
    color: green;
}
.MenuOrderCard .buttons .button.negative {
    flex: 0;
}
.MenuOrderCard .with-you-warning {
    padding-top: 10px;
    text-align: start;
}
.MenuOrderCard div.custom-disabled {
    pointer-events: none;
    opacity: 0.45;
}
.MenuOrderCard .common-cost {
    padding-right: 10px;
    text-decoration: line-through;
    color: #c0c0c0;
}
.MenuOrderCard .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.MenuOrderCard .width-without-cost {
    max-width: calc(100% - 40px);
    display: inline-block;
}
.MenuOrderCard .width-without-count-cost {
    max-width: calc(100% - 55px);
    display: inline-block;
}
.MenuOrderCard .width-without-additional-cost {
    max-width: calc(100% - 80px);
    display: inline-block;
}
.MenuOrderCard .width-without-all-cost {
    max-width: calc(100% - 95px);
    display: inline-block;
}
.MenuOrderCard .for-small-screen {
    display: block;
}
.MenuOrderCard.ui.card > :last-child {
    border-radius: 0!important;
}
.MenuOrderCard.ui.card > .content > .header:not(.ui) {
    margin-top: 0;
}
@media only screen and (max-width: 970px) and (min-width: 850px) {
    .MenuOrderCard .for-small-screen {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}
