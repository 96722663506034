.HomeUserItem.ui.card.trainee {
    box-shadow: 0px 0px 5px 5px rgba(251, 189, 8, 0.8);
}
.HomeUserItem img {
    text-overflow: ellipsis;
    overflow: hidden;
}
.HomeUserItem .extra.content {
    /*text-overflow: ellipsis;*/
    overflow: hidden;
}
.HomeUserItem .thumbnail {
    -webkit-backface-visibility: hidden;
    display: inline-block;
    position: relative;
    /*margin: 0 auto;*/
    overflow: hidden;
    background: #000;
    /*box-shadow: 0 5px 10px rgba(0,0,0,.5);*/
}
.HomeUserItem .thumbnail img {
    display: block;
    max-width: 100%;
    transition: opacity .2s ease-in-out;
}
.HomeUserItem .thumbnail:hover img {
    opacity: .5;
}
.HomeUserItem .thumbnail::after,
.HomeUserItem .thumbnail::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50%;
    transition: transform .4s ease-out;
    color: #fff;
}
.HomeUserItem .thumbnail::after {
    content: attr(data-firstname);
    top: 0;
    left: 0;
    padding-top: 18px;
    transform: translateY(-100%) scale(.8);
    background: rgba(25, 54, 83, 0.7);
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
}
.HomeUserItem .thumbnail::before {
    content: attr(data-lastname);
    left: 0;
    top: 50%;
    padding: 18px 5px;
    transform: translateY(100%) scale(.8);
    background: rgba(7, 147, 91, 0.7);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: #f1f1f1;
    text-align: center;
}
.HomeUserItem .thumbnail:hover::after,
.HomeUserItem .thumbnail:hover::before {
    transform: translateY(0%) scale(1);
}
.ui.cards a.HomeUserItem.ui.card:hover {
    transform: none;
}
.HomeUserItem .small-pseudo-before.thumbnail::before {
    font-size: 0.8rem;
}
.HomeUserItem .small-pseudo-after.thumbnail::after {
    font-size: 1rem;
}

@media only screen and (max-width: 1200px) {
    .HomeUserItem .thumbnail::before {
        padding: 20px 5px;
    }
    .HomeUserItem .thumbnail::after {
        padding-top: 20px;
    }
}
@media only screen and (min-width: 750px) and (max-width: 850px) {
    .HomeUserItem .thumbnail::before {
        padding: 25px 5px;
    }
    .HomeUserItem .thumbnail::after {
        padding-top: 25px;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {
    .HomeUserItem .thumbnail::before {
        padding: 25px 5px;
    }
    .HomeUserItem .thumbnail::after {
        padding-top: 25px;
    }
}
@media only screen and (min-width: 440px) and (max-width: 520px) {
    .HomeUserItem .thumbnail::before {
        padding: 22px 5px;
    }
    .HomeUserItem .thumbnail::after {
        padding-top: 22px;
    }
}

@media only screen and (max-width: 400px) {
    .HomeUserItem .thumbnail::before {
        padding: 30px 5px;
        font-size: 1.2rem!important;
    }
    .HomeUserItem .thumbnail::after {
        padding-top: 30px;
        font-size: 1.5rem;
    }
}
