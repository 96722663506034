.SickList {

}
.SickList .modal input,
.SickList .modal textarea {
    margin-top: 1em;
    -webkit-appearance: none;
    padding: 0.78571429em 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    -o-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-size: 1em;
    width: 100%;
    line-height: 1.2857;
}
.SickList .full-width{
    width: 100%;
}
.SickList .modal label {
    font-size: 1.1rem;
    font-weight: bold;
    padding-right: 1rem;
}

.SickList .ui.segments > .horizontal.segments {
    background-color: #FFF;
}
.SickList .sick-card .ui.pseudo-segment,
.SickList .sick-card .ui.segment {
    padding: 1rem 1.2rem;
}
.SickList .ui.segments.sick-card {
    margin-top: 1rem;
    cursor: pointer;
}
.SickList .ui.segments.sick-card .sick-card_mobile-sub-info,
.SickList .ui.segments.sick-card .sick-card_mobile-sub-header {
    display: none;
}
.SickList .sick-card .sick-card_header {}
.SickList .sick-card .sick-card_header .sick-card_header_number {
    display: flex;
    align-items: center;
}
.SickList .sick-card .sick-card_header .sick-card_header_owner {
    align-items: center;
    display: flex;
}
.SickList .sick-card .sick-card_comment {
    word-break: break-word;
    white-space: pre-line;
}
.SickList .ui.segments.sick-card .sick-card_mobile-sub-header_durations {
    display: none;
}
.SickList .ui.segments.sick-card .sick-card_mobile-sub-header_durations .durations_end-time,
.SickList .ui.segments.sick-card .sick-card_sub-header_durations .durations_end-time {
    margin-left: 20px;
}
.SickList .ui.segments.sick-card .sick-card_sub-header .sick-card_sub-header_days {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.SickList .ui.segments.sick-card .sick-card_sub-header .sick-card_sub-header_days.green {
    color: #000;
    background: rgba(0, 128, 26, 0.3);
    border-top: none!important;
}
.SickList .ui.segments.sick-card .sick-card_sub-header .sick-card_sub-header_days.yellow {
    color: #000;
    background: rgba(248, 255, 34, 0.2);
    border-top: none!important;
}
.SickList .ui.segments.sick-card .sick-card_sub-header .sick-card_sub-header_days.blue {
    color: #000;
    background: rgba(34, 10, 155, 0.2);
    border-top: none!important;
}
.SickList .edit-sick-modal_date-pickers .start-time {
    display: inline-block;
}
.SickList .edit-sick-modal_date-pickers .end-time {
    display: inline-block;
    margin-left: 2rem;
}
@media only screen and (max-width: 390px) {
    .SickList .ui.segments.sick-card .sick-card_mobile-sub-header_durations {
        display: flex;
    }
    .SickList .ui.segments.sick-card .sick-card_sub-header_durations {
        display: none;
    }
}

@media only screen and (max-width: 650px) {
    .SickList .edit-sick-modal_date-pickers label {
      width: 80px;
      display: inline-block;
    }
    .SickList .edit-sick-modal_date-pickers .start-time,
    .SickList .edit-sick-modal_date-pickers .end-time {
        display: block;
        margin-left: 0;
    }
}
