.CreateVacationForm .ui.small.image{
    margin-bottom: 1rem;
}
.CreateVacationForm .ui.table td .image,
.CreateVacationForm .ui.table td .image img{
    max-width: 150px;
}
.CreateVacationForm button.submit.left {
    margin-left: auto;
    margin-right: 0px;
    display: block;
}
.CreateVacationForm form .ui.icon.message.desktop-duration {
    position: absolute;
    width: auto;
    right: 16px;
}
.CreateVacationForm .ui.message .header:not(.ui) {
    font-size: 24px;
}
.CreateVacationForm form .ui.icon.message > .icon:not(.close) {
    font-size: 1.5rem;
}
.CreateVacationForm .ui.message.device-duration {
    display: none;
}
.CreateVacationForm .calendar-clue {
    font-size: 16px;
    padding: 3px 10px;
    margin: 5px;
}
.CreateVacationForm .calendar-clue-container {
    margin-top: 100%;
}
.CreateVacationForm .ui.form .field > label {
    min-width: 165px;
}
@media only screen and (max-width: 950px) {
    .CreateVacationForm .ui.message.device-duration {
        display: flex;
    }
    .CreateVacationForm .ui.message.desktop-duration {
        display: none;
    }
}
