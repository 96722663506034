.Navbar {
    position: fixed;
    top: 73px;
    background-color: #FFF;
    height: 100%;
}

.Navbar .space-navbar {
    height: 100%;
}
