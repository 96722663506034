.UsersSelector .search-cross-icon{
    position: absolute;
    z-index: 15;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    right: 2rem;
    opacity: 0.8;
}
.UsersSelector .ui.search.selection.dropdown > input.search {
    padding-right: 50px;
}

@media screen and (width > 1920px) { 
    .search-block { 
        min-width: 700px !important; 
    }   
} 
 
@media screen and (max-width: 1920px) { 
    .search-block { 
        min-width: 650px !important; 
    }   
} 
 
@media screen and (max-width: 992px) { 
    .search-block { 
        min-width: 480px !important; 
    }   
} 
 
@media screen and (max-width: 570px) { 
    .search-block { 
        min-width: 320px !important; 
    }   
} 
 
@media screen and (max-width: 425px) { 
    .search-block { 
        min-width: 300px !important; 
    }   
} 