.PageSidePanel .ui.vertical.steps.left .step:after {
    right: 100%;
    border-width: 1px 0px 0px 1px;

}
.PageSidePanel .ui.vertical.steps .step:first-child,
.PageSidePanel .ui.vertical.steps .step {
    padding-top: 20px;
    padding-bottom: 20px;
}
