.Profile .custom-disabled {
    background-color: rgba(211, 211, 211, 0.2);
    pointer-events: none;
    opacity: 0.45;
}
.Profile {

    /*text-align: center;*/
}
.Profile .margin-bottom {
    margin-bottom: 1rem;
}
.Profile .ui.attached.segment.without-bottom-border {
    border-bottom: 0;
}
.Profile .content-center {
    margin-left: auto;
    margin-right: auto;
}
.Profile-intro {
    font-size: large;
}
.Profile .radio.checkbox{
    margin-bottom: 1rem;
    /*text-align: center;*/
}
.Profile .card .header {
    margin-bottom: 7px;
}
.Profile .ui.card .meta b {
    color: #000;
}
.ui[class*="top attached"].segment,
.ui.segment[class*="bottom attached"],
.Profile .ui.attached.segment {
    border: 0;
    border-radius: 0;
}
.Profile .profile-sub-card{
    margin: 0 1px;
    box-shadow: 0px 1px 15px 0 rgba(5, 13, 72, 0.1), 0px 4px 15px 0 rgba(5, 13, 72, 0.1);
}
.Profile .ui.card.main-info {
    width: auto;
}
.Profile .ui.card.main-info-image {
    width: auto;
    margin-bottom: 0;
}
.Profile .ui.card.main-info-description {
    margin-top: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    width: auto;
    border-radius: 0!important;
}
.uploadcare--dialog .uploadcare--menu__item,
.uploadcare--dialog .uploadcare--button_primary {
    cursor: pointer;
}
.Profile .uploadcare--widget.uploadcare--widget_status_ready,
.Profile button.uploadcare--widget__button.uploadcare--widget__button_type_open{
    width: 100%;
    cursor: pointer;
}
.Profile .ui.column.grid > [class*="four wide"].column.horizontal-card {
    width: 100%!important;
}
.Profile .horizontal-card .ui.card.main-info {
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
.Profile .profile-sub-card.hr {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
.Profile .profile-sub-card.hr .ProfileStatusesCard {
    width: 600px;
}
.Profile .horizontal-card .ui.card.main-info-image {
    width: 300px;
}
.Profile .horizontal-card .ui.card.main-info-description {
    padding: 3rem 2rem;
    width: 300px;
}
.Profile .ui.card > .content > a.header:hover {
    color: #000;
    cursor: default;
}
@media only screen and (max-width: 1100px) {
    .Profile .ui.column.grid > [class*="four wide"].column,
    .Profile .ui.column.grid > [class*="twelve wide"].column {
        width: 100%!important;
    }
    .Profile .ui.card.main-info {
        flex-direction: row;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
    }
    .Profile .ui.card.main-info-image {
        width: 300px;
    }
    .Profile .ui.card.main-info-description {
        padding: 3rem 2rem;
        width: 300px;
    }
    .Profile .profile-sub-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
    }
    .ProfileRolesCard,
    .ProfileStatusesCard {
        width: 299px;
    }
    .ProfileRolesCard .ui.attached.segment {
        border-left: 1px solid #D4D4D5;
    }
    .ProfileStatusesCard .without-bottom-border {
        height: calc(100% - 47px);
    }
}
@media only screen and (max-width: 850px) {
    .Profile .ui.grid [class*="four wide"].column {
        display: block;
    }
}
@media only screen and (max-width: 650px) {
    .Profile .ui.card.main-info-image,
    .Profile .horizontal-card .ui.card.main-info-image,
    .Profile .ui.card.main-info-description,
    .Profile .horizontal-card .ui.card.main-info-description,
    .ProfileRolesCard,
    .ProfileStatusesCard {
        width: 50%;
    }
    .Profile .ui.card.main-info-description,
    .Profile .horizontal-card .ui.card.main-info-description {
        padding: 3rem 1rem;
    }
    .Profile .profile-sub-card,
    .Profile .profile-sub-card.hr,
    .Profile .ui.card.main-info,
    .Profile .horizontal-card .ui.card.main-info {
        width: auto;
    }
    .Profile .profile-sub-card.hr .ProfileStatusesCard {
        width: 100%;
    }
}
@media only screen and (max-width: 540px) {
    .Profile .ui.card.main-info,
    .Profile .horizontal-card .ui.card.main-info,
    .Profile .profile-sub-card {
        flex-direction: column;
    }
    .Profile .ui.card.main-info-image,
    .Profile .horizontal-card .ui.card.main-info-image,
    .Profile .ui.card.main-info-description,
    .Profile .horizontal-card .ui.card.main-info-description,
    .ProfileRolesCard,
    .ProfileStatusesCard {
        width: auto;
    }
    .Profile .ui.card.main-info-description,
    .Profile .horizontal-card .ui.card.main-info-description {
        padding: 0;
    }
    .ProfileRolesCard .ui.attached.segment {
        border-left: 0;
    }
    .Profile .profile-sub-card {
        margin: 0 1px;
    }
}
