.AdminCalendar {
    height: 100%;
    width: 100%;
    padding: 1rem;
}
.AdminCalendar .react-datepicker__day--outside-month {
    visibility: hidden;
}
.AdminCalendar .react-datepicker__navigation {
    display: none;
}
.AdminCalendar .react-datepicker {
    border: none;
    background: #f7f7f7;
}
.AdminCalendar .react-datepicker .react-datepicker__day--selected {
    color: #000;
    background: #fff;
}
.AdminCalendar .react-datepicker__month-container {
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    background: #fff;
}
.AdminCalendar .spaced .button {
    margin-bottom: 0.35rem;
}
.AdminCalendar .monthCard .right {
    float: right;
}
