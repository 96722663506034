.OrderInfoCard.ui.card {
    width: auto;
}
.OrderInfoCard .description {
    text-align: left;
}
.OrderInfoCard .right {
    float: right;
}
.OrderInfoCard .green {
    color: green;
}
.OrderInfoCard .buttons .button.negative {
    flex: 0;
}
