@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&subset=cyrillic,cyrillic-ext,latin-ext');
body {
  margin: 0;
  padding: 0;
  background-color: #F5F5F5;
  font-family: 'Montserrat';
}
button, input, optgroup, select, textarea,
h1, h2, h3, h4, h5,
.ui.input > input,
.ui.button,
.ui.modal > .header,
.ui.header,
.ui.menu,
.ui.form input[type="text"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.message .header,
.ui.steps .step .title {
  font-family: 'Montserrat';
}
.ui.form input[type="text"],
.uploadcare--widget__button,
.uploadcare--widget__button:hover,
.ui.form textarea {
  font-size: 14px;
}
.ui.selection.dropdown,
.ui.input > input {
  border-radius: 5px;
  border-color: #D6D6D6;
}
.ui.cards > .card,
.ui.card,
.ui.steps,
.ui.segment,
.ui.table,
.ui.menu,
.HomeUserItem .thumbnail,
.ui.styled.accordion .title,
.ui.segments {
  border: 0;
  border-radius: 0;
  /*box-shadow: 0px 3px 15px 0 rgba(5, 13, 72, 0.1);*/
  box-shadow: 0 2px 10px rgba(0, 0, 0, .25);
}
.ui.vertical.steps .step:first-child,
.ui.cards > .card > :first-child, .ui.card > :first-child,
.ui.segments:not(.horizontal) > .segment:first-child,
.ui.segments:not(.horizontal) > .segment:last-child,
.ui.vertical.steps .step:last-child {
  border-radius: 0!important;
}
.ui.menu,
.ui.steps {
  /*box-shadow: 0px 1px 15px 0 rgba(5, 13, 72, 0.1), 0px 4px 15px 0 rgba(5, 13, 72, 0.1);*/
}
.ui.vertical.steps .active.step:after {
  box-shadow: 2px 3px 5px 0 rgba(5, 13, 72, 0.1);
  border-width: 0;
}
.ui.secondary.pointing.menu .item.logout {
  padding: 10px 11px;
  background: #26BB7F;
  border-radius: 25px;
}
.green {
  color: #21BA62;
}
.red {
  color: #F55555;
}
.orange {
  color: #FFA30F;
}
.react-datepicker-popper {
  z-index: 110;
}

#root {
  height: 100%;
}
.error .list {
  overflow: hidden;
}
.error .list li {
  max-height: 90px;
}
.container-with-fixed-header {
  padding-top: calc(73px + 1rem);
  padding-left: calc(64px + 1rem); 
}
.admin-container-with-fixed-header {
  padding-top: 1rem;
}
.ui.message .header:not(.ui) {
  font-size: 2em;
  line-height: 1.3em;
}
.margin-15,
h2:first-child.margin-15{
  margin: 15px;
}
 .ui.card.info.message {
  background-color: #F8FFFF;
  color: #276F86;
}
.flex-1-to-4 .field{
  display: flex;
  align-items: center;
}
.flex-1-to-4 .field > label.with-textarea {
  align-self: flex-start;
  margin-top: 1em;
}
.flex-1-to-4 .field > label {
  flex: 1;
}
.flex-1-to-4 .field > div {
  flex: 4;
}
.flex-block{
  display: flex;
}
.flex-block-wrap{
  display: flex;
  flex-flow: wrap;
}
.pointer {
  cursor: pointer;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.padding-top-5 {
  padding-top: 5px;
}
.white-space-pre-line {
  white-space: pre-line;
}
.no-wrap {
  white-space: nowrap;
}
.inline-flex {
  display: inline-flex;
}
.relative-block {
  position: relative;
}
.color-example {
  width: 15px;
  vertical-align: text-bottom;
  height: 15px;
  display: inline-block;
  background-color: #f89406;
  border: 1px solid #e0e0e0;
}
.color-example.yellow {
  background-color: rgba(248, 255, 34, 0.3);
}
.color-example.blue {
  background-color: rgba(34, 10, 155, 0.3);
}
.color-example.green {
  background: rgba(0, 128, 26, 0.3);
}
.color-example.dark-orange {
  background-color: darkorange;
}
.color-example.medium-blue {
  background-color: mediumblue;
}
.color-example.forest-green {
  background: forestgreen;
}
.color-example.negative {
  background: rgba(159, 58, 56, 0.9);
}
.fixed .ui.vertical.steps {
  width: 100%;
}
.ui.vertical.tabular.menu .item:hover,
.step.pointer:hover,
.menu .item:hover {
  background: rgba(0, 0, 0, 0.03);
}

.react-datepicker__day--highlighted-custom-sick {
  border-radius: 0.3rem;
  background-color: rgba(248, 255, 34, 0.3);
}
.react-datepicker__day--highlighted-custom-vacation {
  border-radius: 0.3rem;
  background-color: rgba(33, 186, 69, 0.3);
}
.react-datepicker__day--highlighted-custom-holidays {
  border-radius: 0.3rem;
  background-color: rgba(236, 33, 69, 0.3);
}
.segment.clearing .custom-floated.right {
  float: right;
}
.ui.grid.custom-reverse {
  flex-direction: row-reverse;
}
.admin-container {
  height: 100%;
  display: flex;
  padding-top: 72px;
}
.ui.column.four .ui.card.fixed,
.ui.column.four .ui.steps.fixed,
.ui.column.four .ui.cards.fixed,
.ui.column.four .ui.message.fixed {
  position: fixed;
  width: calc(25% - 2rem);
  max-width: 290px;
}
.ui.cards.fixed .PageSidePanel {
  max-width: 100%;
  position: relative;
  margin: 0.875em 0.5em;
  width: 290px;
}
.search-block {
  position: fixed;
  width: 75%;
  background: #F5F5F5;
  padding: 1rem;
  margin: 0 -1rem;
  top: 73px;
  z-index: 10;
  max-width: 958px;
}
.alternative-sub-menu {
  display: none;
}
.dimmer.modals.page {
  overflow: auto;
}
.dimmer.modals.page .ui.modal {
  position: relative;
  max-height: 90%;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (min-width: 1275px) {
  .Home, .Menu, .Order, .Request, .Sick, .Vacation, .Events, .Event, .OrderHistory, .Profile {
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 1275px) {
  .Home, .Menu, .Order, .Request, .Sick, .Vacation, .Events, .Event, .OrderHistory, .Profile {
    width: auto;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}

@media only screen and (max-width: 850px) {
  /*
  * these columns changing styles for class .alternative-sub-menu
  * all styles for this class may find in "SideColumnWithMobileBottomLine" component
  */
  .ui.grid [class*="four wide"].column,
  .ui.grid [class*="six wide"].column {
    display: none;
  }

  .ui.grid > [class*="twelve wide"].column,
  .ui.grid > [class*="ten wide"].column {
    width: 100% !important;
    margin-bottom: 55px;
  }

  .SideColumnWithMobileBottomLine .ui.modal .content > .description {
    padding-bottom: 0!important;
  }
  .search-block {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 1100;
  }
  .ui.vertical.steps .active.step:after,
  .ui.vertical.steps .active.step:last-child:after,
  .info.message.hide-on-mobile {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .ui.modal .actions > .button,
  .ui.green.submit.button {
    width: 100%;
    margin-left: 0;
  }
}
@media only screen and (max-width: 600px) {
  .flex-1-to-4 .field {
    flex-direction: column;
    align-items: baseline;
  }
  .flex-1-to-4 .field > label.with-textarea {
    margin-top: 0;
  }
}
@media only screen and (max-width: 460px) {
  .search-block {
    width: 100%;
    display: block;
  }
  .search-block .UsersSelector{
    padding: 0;
  }
  .search-block .search-input {
    width: 100%;
  }
  .search-block .for-admin.search-input{
    margin-top: 1rem;
  }
  .search-block .UsersSelector .ui.search.dropdown{
    width: 100%;
  }
}
