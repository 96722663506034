.AdminTableVacations {
    height: 100%;
    width: 100%;
    position: relative;
}
.AdminTableVacations .flex-block {
    display: flex;
}
.AdminTableVacations .with-left-margin {
    margin-left: 1rem;
}
.AdminTableVacations .user-selector .selection.search {
    width: 100%;
}
.AdminTableVacations .flex-container {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    margin: 1em 0em;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .25);
}
.AdminTableVacations .flex-container .ui.header {
    margin: 0;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    height: 64px;
}
.ui.styled.accordion .AdminTableVacations .flex-container .content {
    padding: 1rem;
}
.ui.styled.accordion .AdminTableVacations .flex-container .ui.header:first-child .content {
    display: flex;
    align-items: center;
    height: 100%;
}
.AdminTableVacations .flex-container .ui.header:first-child {
    border-top: 0;
    background: #F9FAFB;
    height: 75px;
}
.AdminTableVacations .flex-container .fixed-block {
    display: flex;
    flex-direction: column;
}
.AdminTableVacations .scrollable-block {
    display: flex;
    overflow: auto;
}
.AdminTableVacations .text-center {
    text-align: center;
}
.AdminTableVacations .scrollable-block .ui.header:not(:first-child) .content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.AdminTableVacations input {
    /*margin-top: 1em;*/
    /*-webkit-appearance: none;*/
    /*padding: 0.78571429em 1em;*/
    /*background: #FFFFFF;*/
    /*border: 1px solid rgba(34, 36, 38, 0.15);*/
    /*outline: none;*/
    /*color: rgba(0, 0, 0, 0.87);*/
    /*border-radius: 0.28571429rem;*/
    /*-webkit-box-shadow: 0em 0em 0em 0em transparent inset;*/
    /*box-shadow: 0em 0em 0em 0em transparent inset;*/
    /*-webkit-transition: color 0.1s ease, border-color 0.1s ease;*/
    /*-o-transition: color 0.1s ease, border-color 0.1s ease;*/
    /*transition: color 0.1s ease, border-color 0.1s ease;*/
    /*font-size: 1em;*/
    /*width: 100%;*/
    /*line-height: 1.2857;*/
}
