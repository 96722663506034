.NotFound {
    padding-top: calc(73px + 1rem);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.NotFound .ui.image {
    width: 100%;
    max-width: 600px;
}
.NotFound .ui.image img {
    width: 100%;
}
.NotFound .text {
    padding-top: 2rem;
    text-align: center;
    font-weight: 600;
}
