.AdminSettingsPage {
    height: 100%;
    width: calc(100% - 110px);
    padding: 1rem;
}
.AdminSettingsPage .hidden {
    display: none;
}
.AdminSettingsPage .ui.styled.accordion .title {
    background: #fff;
    margin-bottom: 1rem;
}
.AdminSettingsPage .ui.styled.accordion {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
}
