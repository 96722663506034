@import "../../pages/Handbook/handbook-contacts/index.scss";

.handbook-contacts__container {
  .handbook-contacts__item {
    position: relative;

    button {
      display: none;
    }
  }

  .handbook-contacts__item:hover {
    button {
      display: block;
      position: absolute;
      right: 0;
      padding: 1em;
    }
  }
}