.Event {
  position: relative;
}
.Event .ui.vertical.steps.left .step:after {
  right: 100%;
  border-width: 1px 0px 0px 1px;

}
.Event h2.ui.header {
  margin: 0 0 2rem;
}
.Event ul.ui.list.green li:before {
  color: green;
}
.Event .search-input{
  display: flex;
  flex: 1;
  height: 2.75rem;
}
.Event .user-selector{
  padding-right: 1rem;
}
.Event .ui.table th .image,
.Event .ui.table th .image img,
.Event .ui.table td .image,
.Event .ui.table td .image img{
  max-width: 150px;
}
.Event div.absolute-top-right {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
.Event .absolute-top-right button {
  font-size: 2rem !important;
  border: 0.5rem solid white!important;
}
.Event .html {
  min-height: 100%;
}
.Event .text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
