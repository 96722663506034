.dish-rating-modal .ui.modal{
    width:auto;
}

.dish-rating-modal .dish-item{
    font-weight: normal;
    padding: 15px 0px;
    border-bottom: 1px solid lightgray;
    display:flex;

}

.dish-rating-modal .dish-item input{
    margin: 5px;
}

.dish-rating-modal .dish-item div {
    display:flex;
    line-height: 1.6;
}

.dish-rating-modal .dish-item .label{
    width:50%;
}

.dish-rating-modal .dish-item .value{
    margin-left:10px;
    width: 10px;
}

.dish-rating-modal .content, .dish-rating-modal .full-width, .dish-rating-modal .header{
    width: 100%;
}

@media(max-width: 767px) {
    .dish-rating-modal .dish-item{
        display:block;
    }

    .dish-rating-modal .dish-item input{
        margin-top: 5px;
        flex-grow: 1;
        height: 20px;
    }

    .dish-rating-modal .dish-item .label{
        width:100%;
    }

}
