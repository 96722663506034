.AdminEditRole  {
  width: 100%;
}
h2.ui.header {
  margin: 0 0 1rem;
}
.AdminEditRole .card.card-with-table {
  height: 100%;
  display: flex;
  width: 100%;
  padding-bottom: 1.2rem;
  min-height: 400px;
}
.AdminEditRole .card.grid {
  height: 100%;
  width: 100%;
}
