.handbook {
	// padding-bottom: 60px;
	padding-bottom: 0px !important;

	.handbook__title {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 49px;
		color: #3b3b3c;
		margin-bottom: 36px;
		@media (max-width: 480px) {
			font-size: 28px !important;
			margin-bottom: 20px !important;
			line-height: 36px !important;
		}
	}

	.handbook__section {
		margin-top: 100px;
		@media (max-width: 1280px) {
			margin-top: 70px !important;
		}
		@media (max-width: 750px) {
			margin-top: 35px !important;
		}
	}

	.handbook__wrapper {
		padding: 60px;
		@media (max-width: 1280px) {
			padding: 40px 40px 60px 40px !important;
		}
		@media (max-width: 750px) {
			padding: 30px 30px 60px 30px !important;
		}
		@media (max-width: 480px) {
			padding: 20px 20px 60px 20px !important;
		}
		&.handbook__wrapper_dark {
			background-color: #141F25 !important;
			.handbook__title {
				color: #FFFFFF !important;
			}
		}
	}
}
