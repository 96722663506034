ul {
    list-style-type: none;
    padding-right: 40px;
}
.AdminMenu {
    width: 100%;
    height: 100%;
    text-align: center;
}
.AdminMenu .ui.header:first-child.divider {
    margin-top: 3rem;
}
.AdminMenu div.add-dish {
    position: absolute;
    right: 50px;
    left: auto;
    z-index: 10;
    top: 30px;
}
.AdminMenu .message.add-dish {
    margin: 0 6.4em 2em 2em;
}
.AdminMenu .ui.message:last-child {
    margin-bottom: 2em;
}
.AdminMenu .column .floated.right {
    float: right;
    max-width: 110px;
}
.AdminMenu .column .floated.left {
    float: left;
    max-width: 110px;
}
.AdminMenu .card.card-with-table {
    height: 100%;
    display: flex;
    width: 100%;
    padding-bottom: 1.1rem;
}
.AdminMenu .card.card-with-table.tableWithSearch {
    height: calc(100% - 53px);
}
.AdminMenu .html.grid {
    height: 100%;
}
.AdminMenu .ui[class*="top attached"].segment {
    margin-top: 0;
}
