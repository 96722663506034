.handbook-tips {

	.handbook-tips__container {
		padding-top: 20px;
		position: relative;
		gap: 93px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		.handbook-tips__item {
			flex: 100%;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			.handbook-tips__item-header {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				.handbook-tips__item-icon {
					object-fit: contain;
					display: inline-block;
					flex: 0 0 66px;
				}
				.handbook-tips__item-title {
					font-family: Montserrat;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 150%;
					color: #3B3B3C;
					border-bottom: 2px solid #22b374;
					padding-bottom: 14px;
				}
			}
			.handbook-tips__item-content {
				font-family: Montserrat;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 150%;
				color: #3b3b3c;
				max-width: 600px;
				padding-top: 30px;
			}
		}
	}
}

@media (max-width: 750px) {
	.handbook-tips {
		.handbook-tips__container {
			grid-template-columns: 1fr !important;
			gap: 40px !important;
			.handbook-tips__item {
				.handbook-tips__item-header {
					flex-direction: row !important;
					align-items: center !important;
					.handbook-tips__item-icon {
					}
					.handbook-tips__item-title {
						padding: 0 !important;
						border-bottom: unset !important;
						margin: 0 0 0 30px !important;
					}
				}
				.handbook-tips__item-content {
				}
			}
		}
	}
}

@media (max-width: 480px) {
}
