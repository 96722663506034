@media print {
    body * {
        visibility: hidden!important;
    }
    #section-to-print, #section-to-print * {
        visibility: visible!important;
    }
    #section-to-print {
        position: relative;
        left: -50px;
        top: -50px;
        width: calc(100% + 50px)!important;
    }
    #section-to-print .icon.trash {
        visibility: hidden!important;
    }
    .Order .html.ui[class*="top attached"].segment {
        overflow-y: visible;
    }
    .ui.blue.ribbon.label {
        color: black!important;
        background: transparent!important;
    }
    .ui.blue.ribbon.label:after {
        visibility: hidden!important;
    }
    .Order .with-you.hide-with-you{
        visibility: visible!important;
    }
    .Order .with-you {
        right: 10px!important;
        opacity: 0.6;
        border: 0!important;
    }
    .Order .menu-by-table {
        break-before: page;
        margin-top: 55px;
     }
}

.Order .menu .name,
.Order .menu .count,
.Order .menu .tables {
    padding: 10px;
}
.Order .menu .name,
.Order .menu .tables {
    flex: 1;
    flex-flow: wrap;
}
.Order .menu .count .number {
    margin: auto;
}
.Order .menu .count {
    border-left: 1px solid;
    border-right: 1px solid;
    width: 40px;
    padding: 15px;
    display: flex;
}
.Order .menu .tables .table {
    width: 35px;
    padding: 5px;
    margin-left: 10px;
    font-size: 19px;
    align-items: baseline;
    justify-content: center;
}
.Order .menu .tables .table small {
    font-size: 60%;
}
.Order .menu .name {
    align-self: center;
}
.Order .menu .tables .table.circle {
    border: 1px solid;
    border-radius: 20px;
    display: inline-flex;
}



.Order {
    width: 100%;
    height: 100%;
}
.Order .without-orders {
    text-align: center;
}
.Order .ui.header:first-child.divider {
    margin-top: 3rem;
}

.Order .ui.message:last-child {
    margin-bottom: 2em;
}
.Order .column.row.with-margin  {
    margin-bottom: 1em;
    position: relative;
}
.Order .link-for-scrolling  {
    position: absolute;
    top: calc(-73px - 1rem);
}
.Order .ui.list  {
    margin: 0;
}
.Order .column.grid.right  {
    justify-content: flex-end;
}
.Order .ui.grid > .row:not(:first-child) {
    padding-top: 0;
}
.Order .segment.flex {
    display: flex;
}
.Order .ui.segment.flex {
    box-shadow: 0px 0px 1px 1px rgba(34, 36, 38, 0.15)
}
.Order .user-name {
    width: 25%;
    min-width: 120px;
    padding-right: 5px;
}
.Order .ui[class*="top attached"].segment {
    margin-top: 0;
    height: 100%;
    overflow-y: auto;
}
.Order .fixed {
    position: fixed;
}
.Order .icon.trash {
    right: 10px;
    position: absolute;
    font-size: 18px;
    top: 15px;
    cursor: pointer;
}
.Order .with-you.hide-with-you{
    visibility: hidden;
}
.Order .with-you {
    position: absolute;
    right: 1rem;
    opacity: 0.8;
    top: 10px;
    background: #21BA45;
    border: 1px solid #21BA45;
    border-radius: 20px;
    color: #fff;
    padding: 3px 10px;
    font-size: 15px;
}
.Order .ui.segment.with-you-background {
    background: lightyellow;
}
.Order.admin-container-with-fixed-header {
    width: 100%;
    padding-left: 1.5rem;
}
.Order .ui.ribbon.label:hover {
    background-color: #2185D0 !important;
    border-color: #1a69a4 !important;
}
.Order .ui.ribbon.label {
    top: -8px;
    cursor: default;
}
.Order .description > div,
.Order .ui.list > .item {
    padding-top: 7px;
}
@media only screen and (max-width: 850px) {
    .Order .column.row.with-margin  {
        margin-left: 1em;
    }
    .Order .with-you {
        font-size: 20px;
    }
    .Order .ui.order-mobile-side-menu {
        flex-direction: column;
        display: block;
    }
    .Order .order-mobile-side-menu .PageSidePanel {
        background: #FFF;
    }
    .Order .SideColumnWithMobileBottomLine .ui.modal,
    .Order .SideColumnWithMobileBottomLine .ui.modal .image.content {
        background: none;
    }
    .Order .fixed {
        position: static;
    }
}
@media only screen and (max-width: 650px) {
    .Order .column.row.with-margin  {
        margin-left: 1em;
    }
    .Order .with-you {
        opacity: 0;
    }
}
