.handbook-principles {

	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	color: #3b3b3c;
    

	ul.handbook-principles_list {
		list-style: none;
		padding: 0 0 0 26px;
		> li {
			padding: 10px 0;
			&::before {
				position: relative;
				left: -20px;
				content: "\25A0";
				color: #1eb157;
				font-weight: bold;
				display: inline-block;
				width: 6px;
				margin-left: -6px;
			}
		}
	}
	.handbook-principles__container {
		position: relative;
		left: -13px;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		gap: 32px;
		.handbook-principles__flex-column {
			flex: 50%;
			max-width: 50%;
			display: flex;
			align-items: stretch;
			flex-direction: column;
			.handbook-principles__item {
				margin: 13px;
				flex: 100%;
				box-shadow: 0px 5px 14px rgba(81, 81, 81, 0.16);
				border-top: 4px solid #22b374;
				display: flex;
				align-items: flex-start;
				.handbook-principles__item-icon {
					padding: 40px;
					object-fit: contain;
					display: inline-block;
					flex: 0 0 42px;
				}
				.handbook-principles__item-content {
					padding: 40px 40px 40px 0;
				}
			}
		}
	}
}

@media (max-width: 1280px) {
	.handbook-principles__container {
        display: block !important;
		.handbook-principles__flex-column {
            flex: 100% !important;
            max-width: 100% !important;
			.handbook-principles__item {
				.handbook-principles__item-icon {
				}
				.handbook-principles__item-content {
				}
			}
		}
	}

}

@media (max-width: 750px) {
    .handbook-principles__container {
        left: 0 !important;
        gap: unset !important;
		.handbook-principles__flex-column {
			.handbook-principles__item {
				.handbook-principles__item-icon {
                    padding: 30px !important;
				}
				.handbook-principles__item-content {
                    padding: 30px 30px 30px 0 !important;
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.handbook-principles__container {
		.handbook-principles__flex-column {
			.handbook-principles__item {
                flex-direction: column !important;
		        align-items: center !important;
				.handbook-principles__item-icon {
                    padding: 25px !important;
				}
				.handbook-principles__item-content {
                    padding: 0 20px 20px 20px !important;
				}
			}
		}
	}
}
