.Events .ui.vertical.steps.left .step:after {
  right: 100%;
  border-width: 1px 0px 0px 1px;

}
.Events h2.ui.header {
  margin: 0 0 1rem;
}
.Events ul.ui.list.green li:before {
  color: green;
}
.Events .search-input{
  display: flex;
  flex: 1;
  height: 2.75rem;
}
.Events .user-selector{
  padding-right: 1rem;
}
.Events .ui.table th .image,
.Events .ui.table th .image img,
.Events .ui.table td .image,
.Events .ui.table td .image img{
  max-width: 150px;
}
.Events .padding-for-search-block {
  padding-top: 40px;
}
@media only screen and (max-width: 850px) {

}
@media only screen and (max-width: 460px) {
  .Events .search-block .for-admin.search-input {
    margin-top: 0;
  }
}
