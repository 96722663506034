.AdminVacationsList {

}
.AdminVacationsList table .half-table {
    width: 50%;
}
.AdminVacationsList .ui.table:first-child {
    margin-top: 1em;
}
.AdminVacationsList table .ten-percent {
    width: 10%;
}

.AdminVacationsList textarea {
    margin-top: 1em;
    -webkit-appearance: none;
    padding: 0.78571429em 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    -o-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-size: 1em;
    width: 100%;
    line-height: 1.2857;
}
.AdminVacationsList .full-width{
    width: 100%;
}
.AdminVacationsList .all-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.AdminVacationsList .ui.table .yellow {
    background: rgba(248, 255, 34, 0.2);
}
.AdminVacationsList .ui.table .blue {
    background: rgba(34, 10, 155, 0.2);
}
.AdminVacationsList .ui.table .green {
    background: rgba(0, 128, 26, 0.3);
}
@media only screen and (max-width: 400px) {
    .AdminVacationsList .ui.modal .actions > .button {
        width: 100%;
        margin-left: 0;
    }
}
