.Chat .comment-input{
    width: 100%;
    padding-left: 1rem;
}
.Chat .ui.input.comment-input input{
   margin-right: 140px;
}
.Chat .ui.list > .item {
    display: flex;
    padding-left: 10px;
}
.Chat .ui.list .ui.avatar.image {
    width: 3em;
    height: 3em;
}
.Chat .ui.list > .item > .image + .content {
    padding-left: 15px;
}
.Chat .ui.list > .item > .image + .content .header{
    padding-bottom: 15px;
}
.Chat .ui.button.send-button {
    position: absolute;
    right: 1rem;
    top: 0.7rem;
}
