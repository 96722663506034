.Checkbox {
    min-width: 35px;
    max-width: 35px;
    position: relative;
    height: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-self: center;
}

/* Hide the browser's default checkbox */
.Checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    width: 20px;
    height: 20px;
}

/* Create a custom checkbox */
.Checkbox label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: #fff;
    border:1px solid #ddd;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkbox label:after {
    content: "";
    position: absolute;
    display: none;
 }
/* On mouse-over, add a grey background color */
.Checkbox:hover label{
    background-color: #ccc;
}
.Checkbox input:checked ~ label {
    background-color: #fff;
}

/* Show the checkmark when checked */
.Checkbox input:checked ~ label:after {
    display: block;
}

/* Style the checkmark/indicator */
.Checkbox label:after {
    left: 7px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.CreateEventForm .field .Checkbox label {
    min-width: 20px;
}
