.CreateSickForm .ui.small.image{
    margin-bottom: 1rem;
}
.CreateSickForm .ui.table td .image,
.CreateSickForm .ui.table td .image img{
    max-width: 150px;
}
.CreateSickForm button.submit.left {
    margin-left: auto;
    margin-right: 0px;
    display: block;
}
.CreateSickForm .ui.input > input[type="date"] {
    flex: 0.3;
}
.CreateSickForm .ui.message .header:not(.ui) {
    font-size: 24px;
}
.CreateSickForm form .ui.icon.message.desktop-duration {
    position: absolute;
    width: auto;
    right: 16px;
}
.CreateSickForm form .ui.icon.message > .icon:not(.close) {
    font-size: 1.5rem;
}
.CreateSickForm .ui.message.device-duration {
    display: none;
}
@media only screen and (max-width: 600px) {
    .CreateSickForm .ui.message.device-duration {
        display: flex;
    }
    .CreateSickForm .ui.message.desktop-duration {
        display: none;
    }
}
