.EventsList {

}
.EventsList .no-list {
    margin: 10px 25px;
    text-align: center;
}
.EventsList table .half-table {
    width: 50%;
}
.EventsList table .ten-percent {
    width: 10%;
}

.EventsList .event-card .ui.pseudo-segment,
.EventsList .event-card .ui.segment {
    padding: 1rem 1.2rem;;
}

.EventsList .ui.segments.event-card {
    margin-top: 1rem;
    cursor: pointer;
}
.EventsList .ui.segments.event-card .event-card_mobile-sub-header {
    display: none;
}
.EventsList .event-card .event-card_header {}
.EventsList .event-card .event-card_header .event-card_header_number {
    display: flex;
    align-items: center;
}
.EventsList .event-card .event-card_header .event-card_header_title {
    align-items: center;
    display: flex;
}
.EventsList .event-card .event-card_header .event-card_header_buttons {
    display: flex;
    align-items: center;
    flex: none;
}
.EventsList .event-card .event-card_mobile-sub-header .event-card_header_buttons .participants,
.EventsList .event-card .event-card_header .event-card_header_buttons .participants {
    cursor: default;
    background: #FFF;
    border: 1px solid #C9C9C9;
    border-radius: 20px;
    color: #2F2F2F;
    font-weight: normal;
}
.EventsList .event-card .event-card_description {
    word-break: break-word;
    white-space: pre-line;
}

.EventsList .ui.segments > .horizontal.segments {
    background-color: #FFF;
}
.EventsList .event-card .ui.pseudo-segment.event-card_header_buttons {
    padding: 0.7rem 1rem 0.7rem 0;
}
.EventsList .event-card .ui.segment.event-card_description div {
    padding-top: 5px;
}

.EventsList .event-card .event-card_header .event-card_header_buttons.event-type-sport .participants {
  background: hsl(0deg 100% 50% / 20%);
}

.EventsList .event-card .event-card_header .event-card_header_buttons.event-type-party .participants {
  background: hsl(50deg 100% 50% / 20%);
}
.EventsList .event-card .event-card_header .event-card_header_buttons.event-type-other .participants {
  background: hsl(100deg 100% 50% / 20%);
}
.EventsList .event-card .event-card_header .event-card_header_buttons.event-type-online .participants {
  background: hsl(1500deg 100% 50% / 20%);
}

@media only screen and (min-width: 850px) {
    .EventsList .event-card .ui.pseudo-segment,
    .EventsList .event-card .ui.segment {
        padding: 1.4rem 1.7rem;
    }
    .EventsList .event-card .ui.pseudo-segment.event-card_header_buttons {
        padding: 0.7rem 1.7rem 0.7rem 0;
    }
}
@media only screen and (max-width: 600px) {
    .EventsList .event-card .event-card_header .event-card_header_buttons .participants.only-icon-for-small-width span {
        display: none;
    }
    .EventsList .event-card .event-card_header .event-card_header_buttons .participants.only-icon-for-small-width i {
        margin: 0;
    }
}
@media only screen and (max-width: 500px) {
    .EventsList .event-card .event-card_header .event-card_header_buttons {
        display: none;
    }

    .EventsList .event-card .ui.pseudo-segment.event-card_header_buttons {
        padding: 0.7rem 0;
    }

    .EventsList .ui.segments.event-card .event-card_mobile-sub-header {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        flex: none;
    }
}
@media only screen and (max-width: 460px) {
    .EventsList .event-card .event-card_mobile-sub-header .event-card_header_buttons .comments.only-icon-for-small-width {
        display: none;
    }
}
