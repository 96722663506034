.BrokenPage {
    padding-top: calc(73px + 1rem);
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.BrokenPage .ui.image {
    width: 100%;
    max-width: 600px;
}
.BrokenPage .ui.image img {
    width: 100%;
}
.BrokenPage .text {
    padding-top: 2rem;
    text-align: center;
    font-weight: 600;
    display: block;
}
.BrokenPage .text .button {
    border-radius: 20px;
    margin: 0;
}
