.AdminOrderForm  {
  width: 100%;
}
h2.ui.header {
  margin: 0 0 1rem;
}
.AdminOrderForm .card.card-with-table {
  height: 100%;
  display: flex;
  width: 100%;
  padding-bottom: 1.2rem;
  min-height: 400px;
}
.ui.modal .AdminOrderForm .card.card-with-table {
  min-height: 300px;
}
.AdminOrderForm .card.grid {
  height: 100%;
  width: 100%;
}
.AdminOrderForm .select-owner {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.AdminOrderForm .user-selector-wrapper {
  margin-left: 1rem;
}
