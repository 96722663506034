.CurrentStatistic.ui.card {
    width: auto;
}
.CurrentStatistic .wide-content {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
}
.CurrentStatistic .content.pointer:hover {
    background: rgba(0, 0, 0, 0.03);
}
