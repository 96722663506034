.Signup {
  background-color: #f5f5f5;
  padding: 2rem 1rem 0;
  display: flex;
  flex-direction: column;
  /*height: 100%;*/
  overflow: auto;
}
.Signup .ui.grid {
  overflow: auto;
}
.Signup .ui .column {
  max-width: 450px;
}
.Signup .ui.message:last-child {
  margin-bottom: 1em;
}
.Signup .ui.image.header {
  display: block;
}
.Signup .ui.segment h2 {
  margin-bottom: 35px;
}
.Signup .ui.segment {
  padding: 2rem;
}
.Signup .login-link {
  margin-top: 1rem;
}
