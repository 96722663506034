.AdminSidebar {
    display: flex;
    min-width: 110px;
}
.AdminSidebar .sidebar.menu{
    position: inherit;
}
.AdminSidebar .sidebar.menu a.hidden{
    display: none;
}
.AdminSidebar .ui.vertical.icon.menu{
    width: 110px;
    position: fixed;
    padding-top: 72px;
}
.AdminSidebar-logo {
    /*animation: AdminSidebar-logo-spin infinite 20s linear;*/
    height: 80px;
    margin-top: 1.5em;
}

@keyframes AdminSidebar-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.masthead .ui.menu .ui.button {
    margin-left: 0.5em !important;
}
.masthead h1.ui.AdminSidebar {
    font-size: 2em;
    margin-top: 1.5em !important;
}
.secondary.pointing.menu .toc.item {
    display: none;
}
@media only screen and (max-width: 700px) {
    .secondary.pointing.menu .toc.item {
        display: block !important;
    }
    .ui.fixed.menu {
        display: none !important;
    }
    .secondary.pointing.menu a.item,
    .secondary.pointing.menu .menu {
        display: none !important;
    }

    .masthead.segment {
        min-height: 350px;
    }
    .masthead h1.ui.AdminSidebar {
        font-size: 2em;
        margin-top: 1.5em;
    }
    .masthead h2 {
        margin-top: 0.5em;
        font-size: 1.5em;
    }
}
