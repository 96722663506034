.AdminUserRoles  {
  width: 100%;
}
h2.ui.header {
  margin: 0 0 1rem;
}
.AdminUserRoles .card.card-with-table {
  height: 100%;
  display: flex;
  width: 100%;
  padding-bottom: 1.2rem;
  min-height: 400px;
}
.AdminUserRoles .card.grid {
  height: 100%;
  width: 100%;
}
.AdminUserRoles .ui.label.inner-label {
  border-radius: 0;
  padding-right: 2rem;
  min-width: 120px;
}
.AdminUserRoles .editAdminUserRoles{
  padding: 1em 0 0.5em;
  cursor: pointer;
}
.AdminUserRoles .editAdminUserRoles .icon{
  margin-left: 20px;
  color: #FDA345;
}
.AdminUserRoles a.ui.label.tag {
  cursor: pointer;
  background-color: #fffaf3;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
  color: #573a08;
}
.AdminUserRoles .label:before{
  box-shadow: 2px 2px 0px -1px #c9ba9b inset, 0 0 0 0 transparent;
}
.AdminUserRoles .ui[class*="top attached"].segment {
  margin-top: 0;
}
.AdminUserRoles a.ui.label.tag:hover {
  background-color: #fff6f2;
  color: rgba(0, 0, 0, 0.8);
}
.AdminUserRoles .ui.icon.input > i.icon {
  opacity: 1;
}
.AdminUserRoles .user-selector-wrapper {
  vertical-align: top;
  height: 38px;
}
.AdminUserRoles .ui.label.right-attached-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.AdminUserRoles input.disabled {
  pointer-events: none;
  opacity: 0.45;
}
