.RequestsList {

}
.RequestsList .no-list {
    margin: 10px 25px;
    text-align: center;
}
.RequestsList .ui.green.button:hover,
.RequestsList .ui.green.button:active {
    background-color: #21BA62;
}
.RequestsList .ui.orange.button:hover,
.RequestsList .ui.orange.button:active {
    background-color: #F2711C;
}
.RequestsList .ui.red.button:hover,
.RequestsList .ui.red.button:active {
    background-color: #DB2828;
}
.RequestsList table .half-table {
    width: 50%;
}
.RequestsList table .ten-percent {
    width: 10%;
}
.RequestsList .ui.table:first-child {
    margin-top: 1em;
}
.RequestsList .request-card_sub-info_images .ui.small.image img {
    max-height: 70px;
}
.RequestsList .request-card_sub-info_images .ui.small.image {
    width: auto;
}

.RequestsList .request-card .ui.pseudo-segment,
.RequestsList .request-card .ui.segment {
    padding: 1rem 1.2rem;;
}

.RequestsList .ui.segments.request-card {
    margin-top: 1rem;
}
.RequestsList .ui.segments.request-card .request-card_mobile-sub-info,
.RequestsList .ui.segments.request-card .request-card_mobile-sub-header {
    display: none;
}
.RequestsList .request-card .request-card_header {}
.RequestsList .request-card .request-card_header .request-card_header_number {
    display: flex;
    align-items: center;
}
.RequestsList .request-card .request-card_header .request-card_header_title {
    align-items: center;
    display: flex;
}
.RequestsList .request-card .request-card_header .request-card_header_buttons {
    display: flex;
    align-items: center;
    flex: none;
}
.RequestsList .request-card .request-card_mobile-sub-header .request-card_header_buttons .days-numbers,
.RequestsList .request-card .request-card_header .request-card_header_buttons .days-numbers {
    cursor: default;
    background: #FFF;
    border: 1px solid #C9C9C9;
    border-radius: 20px;
    color: #2F2F2F;
    font-weight: normal;
}
.RequestsList .request-card .request-card_mobile-sub-header .request-card_header_buttons .status,
.RequestsList .request-card .request-card_header .request-card_header_buttons .status {
    cursor: default;
    border-radius: 20px;
    color: #FFF;
    margin-left: 0.7rem;
}
.RequestsList .request-card .request-card_mobile-sub-header .request-card_header_buttons .status .icon,
.RequestsList .request-card .request-card_header .request-card_header_buttons .status .icon {
    color: #FFF;
}
.RequestsList .request-card .request-card_description {
    word-break: break-word;
    white-space: pre-line;
}
.RequestsList .request-card .request-card_comment {
    word-break: break-word;
    white-space: pre-line;
    padding-left: 1rem;
}
.RequestsList .request-card .request-card_sub-info {}
.RequestsList .request-card .request-card_sub-info .request-card_sub-info_info {}
.RequestsList .request-card .request-card_sub-info .request-card_sub-info_images {}


.RequestsList .ui.segments > .horizontal.segments {
    background-color: #FFF;
}
.RequestsList .ui.horizontal.segments > .segment.request-card_sub-info_images {
    border-left: 0;
}
.RequestsList .ui.segments > .horizontal.segments.request-card_sub-info {
    border-top: 0;
}
.RequestsList .request-card .ui.pseudo-segment.request-card_header_buttons {
    padding: 0.7rem 1rem 0.7rem 0;
}
.RequestsList .request-card .request-card_sub-info .ui.segment.request-card_sub-info_images,
.RequestsList .request-card .request-card_sub-info .ui.pseudo-segment.request-card_sub-info_info {
    padding-top: 0;
}
.RequestsList .request-card .request-card_sub-info .ui.pseudo-segment.request-card_sub-info_info div {
    padding-top: 5px;
}
.RequestsList .request-card .request-card_sub-info .ui.pseudo-segment.request-card_sub-info_info b {
    padding-left: 5px;
}

.RequestsList .request-card .request-card_sub-info_images .pdf-description {
    padding: 10px;
}

.RequestsList .request-card .request-card_sub-info_images .pdf-link {
    display: block;
    width: fit-content;
    margin-left: auto;
    text-decoration: underline;
}

.RequestsList .request-card .request-card_sub-info_images a {
    color: unset;
}

@media only screen and (max-width: 1120px) {
    .RequestsList .request-card .request-card_header .request-card_header_buttons .status.only-icon-for-small-width span {
        display: none;
    }
    .RequestsList .request-card .request-card_header .request-card_header_buttons .status.only-icon-for-small-width i {
        margin: 0;
    }
}
@media only screen and (max-width: 650px) {
    .RequestsList .request-card .request-card_header .request-card_header_buttons .days-numbers.only-icon-for-small-width span {
        display: none;
    }
    .RequestsList .request-card .request-card_header .request-card_header_buttons .days-numbers.only-icon-for-small-width i {
        margin: 0;
    }
}
@media only screen and (min-width: 850px) {
    .RequestsList .request-card .ui.pseudo-segment,
    .RequestsList .request-card .ui.segment {
        padding: 1.4rem 1.7rem;
    }
    .RequestsList .request-card .ui.pseudo-segment.request-card_header_buttons {
        padding: 0.7rem 1.7rem 0.7rem 0;
    }
}
@media only screen and (max-width: 500px) {
    .RequestsList .request-card .request-card_header .request-card_header_buttons {
        display: none;
    }

    .RequestsList .request-card .ui.pseudo-segment.request-card_header_buttons {
        padding: 0.7rem 0;
    }

    .RequestsList .ui.segments.request-card .request-card_mobile-sub-header {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        flex: none;
    }
}
@media only screen and (max-width: 460px) {
    .RequestsList .request-card .request-card_mobile-sub-header .request-card_header_buttons .days-numbers.only-icon-for-small-width span {
        display: none;
    }
    .RequestsList .request-card .request-card_mobile-sub-header .request-card_header_buttons .days-numbers.only-icon-for-small-width i {
        margin: 0;
    }
    .RequestsList .request-card .request-card_sub-info .ui.segment.request-card_sub-info_images {
        display: none;
    }
    .RequestsList .ui.segments.request-card .request-card_mobile-sub-info .ui.segment.request-card_sub-info_images {
        display: flex;
        align-items: center;
        border: 0;
        text-align: left;
    }
    .RequestsList .ui.segments.request-card .request-card_mobile-sub-info .ui.segment.request-card_sub-info_images .pdf-link {
        margin-left: 0;
    }
    .RequestsList .ui.segments.request-card .request-card_mobile-sub-info {
        display: block;
        align-items: center;
        padding: 0;
        border-top: 0;
    }
}
@media only screen and (max-width: 360px) {
    .RequestsList .request-card .request-card_mobile-sub-header .request-card_header_buttons .status.only-icon-for-small-width span {
        display: none;
    }
    .RequestsList .request-card .request-card_mobile-sub-header .request-card_header_buttons .status.only-icon-for-small-width i {
        margin: 0;
    }
}
