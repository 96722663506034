.AdminNews  {
  width: 100%;
}
h2.ui.header {
  margin: 0 0 1rem;
}
.AdminNews .card.card-with-table {
  height: 100%;
  display: flex;
  width: 100%;
  padding-bottom: 1.2rem;
  min-height: 400px;
}
.AdminNews .card.grid {
  height: 100%;
  width: 100%;
}
.AdminNews .ui.button {
  margin-top: 1rem;
}
.AdminNews .hidden-news {
  opacity: 0.5;
}
.AdminNews .editAdminNews{
  padding: 1em 0 0.5em;
  cursor: pointer;
}
.AdminNews .editAdminNews .icon{
  margin-left: 20px;
  color: #FDA345;
}
.AdminNews a.ui.label.tag {
  cursor: pointer;
  background-color: #fffaf3;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
  color: #573a08;
}
.AdminNews .label:before{
  box-shadow: 2px 2px 0px -1px #c9ba9b inset, 0 0 0 0 transparent;
}
.AdminNews .ui[class*="top attached"].segment {
  margin-top: 0;
}
.AdminNews a.ui.label.tag:hover {
  background-color: #fff6f2;
  color: rgba(0, 0, 0, 0.8);
}
.AdminNews .ui.icon.input > i.icon {
  opacity: 1;
}
