.UserStatistic {
    width: 100%;
}
.UserStatistic .pie-chart-statistic {
    width: 100px;
    height: 100px;
    transform: rotate(-90deg);
    border-radius: 50%;
}
.UserStatistic .pie-chart-statistic .full-pie {
    fill: forestgreen;
    stroke: forestgreen;
    stroke-width: 40px;
}
.UserStatistic .pie-chart-statistic .big-pie {
    fill: transparent;
    stroke: blue;
    stroke-width: 44px;
    opacity: 0.9;
    -webkit-transition: stroke-dasharray 0.2s ease-out;
    -moz-transition: stroke-dasharray 0.2s ease-out;
    -o-transition: stroke-dasharray 0.2s ease-out;
    transition: stroke-dasharray 0.2s ease-out;
}

.UserStatistic .pie-chart-statistic .small-pie {
    fill: transparent;
    stroke: darkorange;
    stroke-width: 50px;
    -webkit-transition: stroke-dasharray 0.2s ease-out;
    -moz-transition: stroke-dasharray 0.2s ease-out;
    -o-transition: stroke-dasharray 0.2s ease-out;
    transition: stroke-dasharray 0.2s ease-out;
    stroke-dashoffset: -1px;
}
.UserStatistic .statistic-info {
    display: inline-flex;
    vertical-align: bottom;
    padding-left: 1rem;
}
.UserStatistic .date-selector-block > div {
    padding-left: 1rem;
}
@media only screen and (max-width: 850px) {
    .UserStatistic .ui.grid > [class*="four wide"].column {
        width: 100%!important;
    }
    .UserStatistic .ui.vertical.tabular.menu {
        flex-direction: row;
        display: flex;
        flex: 1;
        border-right: 0;
    }
    .UserStatistic .ui.vertical.tabular.menu .item,
    .UserStatistic .ui.vertical.tabular.menu .active.item {
        width: 25%;
        border: 1px solid #D4D4D5;
        margin: 0;
        border-radius: 0!important;
        border-right: 0;
    }
    .UserStatistic .ui.vertical.tabular.menu .item:first-child,
    .UserStatistic .ui.vertical.tabular.menu .active.item:first-child {
        border-radius: 0.28571429rem 0 0 0.28571429rem!important;
    }
    .UserStatistic .ui.vertical.tabular.menu .item:last-child,
    .UserStatistic .ui.vertical.tabular.menu .active.item:last-child {
        border-radius: 0 0.28571429rem 0.28571429rem 0!important;
        border-right: 1px solid #D4D4D5;
    }
    .UserStatistic .ui.vertical.tabular.menu .active.item {
        background: rgba(0, 0, 0, 0.03);
    }
}
@media only screen and (max-width: 540px) {
    .UserStatistic .ui.vertical.tabular.menu {
        flex-direction: column;
        border-right: 0;
    }
    .UserStatistic .ui.vertical.tabular.menu .item,
    .UserStatistic .ui.vertical.tabular.menu .active.item {
        width: 100%;
        border: 1px solid #D4D4D5;
        border-bottom: 0;
    }
    .UserStatistic .ui.vertical.tabular.menu .item:first-child,
    .UserStatistic .ui.vertical.tabular.menu .active.item:first-child {
        border-radius: 0.28571429rem 0.28571429rem 0 0!important;
    }
    .UserStatistic .ui.vertical.tabular.menu .item:last-child,
    .UserStatistic .ui.vertical.tabular.menu .active.item:last-child {
        border-radius: 0 0 0.28571429rem 0.28571429rem!important;
        border-bottom: 1px solid #D4D4D5;
    }
}
@media only screen and (max-width: 460px) {
    .UserStatistic .ui.grid > .row.date-selector-block {
        flex-direction: column;
    }
    .UserStatistic .date-selector-block .ui.selection.dropdown {
        width: 100%;
    }
    .UserStatistic .date-selector-block > div {
        padding-right: 1rem;
    }
}
