.ProfileSalaryFormula {
    width: 100%;
}
.ProfileSalaryFormula .date-selector-block {
    display: flex;
    padding-bottom: 1rem;
}
.ProfileSalaryFormula .date-selector-block > div {
    padding-left: 1rem;
}
.ProfileSalaryFormula .formula {
    overflow: auto;
}
.ProfileSalaryFormula .formula img {
    width: 100%;
    min-width: 600px;
}
@media only screen and (max-width: 460px) {
    .ProfileSalaryFormula .ui.grid > .row.date-selector-block {
        flex-direction: column;
    }
    .ProfileSalaryFormula .date-selector-block .ui.selection.dropdown {
        width: 100%;
    }
    .ProfileSalaryFormula .date-selector-block > div {
        padding-right: 1rem;
    }
}
