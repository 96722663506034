.Header {
    width: 100%;
    z-index: 105;
    position: fixed;
}
.Header .item {
    position: relative;
}
.Header .item:hover ul.submenu {
    display: block;
}
.Header .item ul.submenu {
    display: none;
    position: absolute;
    top: 32px;
    background-color: #1c1d1e;
    left: 0;
    padding: 12px 0 0 0 !important;
    list-style: none;
    min-width: 100%;
}
.Header .item ul.submenu.shown {
    display: block;
}
.Header .item ul.submenu li.submenu__item {
    font-size: 16px;
    padding: 0 18px 14px 18px;
    line-height: 120%;
    text-transform: none;
    text-align: left;
}
.Header .item ul.submenu a.submenu__item_link {
    color: #fff;
}
.Header .item ul.submenu a.submenu__item_link:hover,
.Header .item ul.submenu a.submenu__item_link.active {
    color: #1EB157;
}
.Header .ui.secondary.inverted.pointing.menu .item,
.Header .menu.vertical.sidebar .menu-title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
}
.Header .large.menu.pointing {
    height: 45px;
}
.Header-logo {
    /*animation: Header-logo-spin infinite 20s linear;*/
    height: 40px;
    padding-right: 10px;
    /* background: #fff; */
    margin-right: 10px;
}
.Header .dropdown .avatar.image {
    margin-bottom: -8px;
    margin-top: -7px;
}
@keyframes Header-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
.Header a.item:not(.active):not(:hover) img.menu-item {
    opacity: 0.7;
}
.Header .ui.sidebar.inverted.menu a.item:not(.active):not(:hover) {
    color: rgba(255, 255, 255, 0.7) !important;
}
.Header .sidebar-ground.hidden {
    visibility: hidden;
}
@media screen and (min-width: 750px) {
    .Header .sidebar-ground {
        display: none !important;
    }
}
.Header .sidebar-ground {
    width: 100%;
    height: 100%;
    z-index: 100;
    position: fixed;
    background: rgba(0, 0, 0, 0.1);
}
.Header .ui.vertical.menu .item:first-child:before {
    display: block!important;
}
.Header .flex-block {
    flex: 1;
}
.Header .secondary.pointing.menu .toc.item {
    display: none;
}
.Header .ui.uncover.sidebar.menu {
    top: 73px;
    z-index: 103;
    height: calc(100% - 73px) !important;
}
.Header .menu .dropdown .Logout {
    display: none;
}
.Header .menu.vertical.sidebar .menu-title {
    display: inline-flex;
    vertical-align: super;
    margin-left: 10px;
}
.Header .menu.vertical.sidebar .label {
    top: 30%;
}
.Header .menu .Logout {
    margin-left: 0.5rem;
}
.Header .ui.floating.label {
    top: -.6em;
    left: 90%;
}
.Header img.menu-item {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.Header .logout img.menu-item {
    margin-right: 0;
}
.Header .ui.secondary.pointing.menu .item.avatar {
    padding: 12px 10px;
    margin-right: 10px;
    margin-top: 2px;
}
.Header .ui.avatar.image {
    width: 42px;
    height: 42px;
    margin-right: 0;
}
.Header .ui.secondary.inverted.pointing.menu a.item.logout:hover {
    background-color: #26BB7F;
    opacity: 0.7;
}
.Header .ui.secondary.inverted.pointing.menu .active.item {
    border-color: #26BB7F;
}
.Header .ui.secondary.pointing.menu .item {
    align-self: center;
}
.Header .flex-block .avatar.item .menu-title,
.Header .flex-block .avatar.item:hover .menu-title,
.Header .flex-block .logout.item .menu-title,
.Header .flex-block .logout.item:hover .menu-title {
    display: none;
}

.Header .sidebar-left { 
    display: grid; 
    align-items: center; 
    justify-items: center; 
    margin-right: 24px; 
    opacity: 0.7; 
} 
 
.Header .sidebar-left:hover { 
    opacity: 1; 
    cursor: pointer; 
}

@media only screen and (min-width: 1276px) {
    .Header .ui.container {
        width: 1250px;
    }
}
@media only screen and (max-width: 1275px) {
    .Header .ui.container {
        width: auto;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}
@media only screen and (max-width: 1200px) {
    .Header .flex-block .menu-title {
        display: none;
    }
    .Header .flex-block .item.active .menu-title {
        display: block;
    }
    .Header .flex-block .item:hover .menu-title {
        display: block;
    }
    .Header .flex-block .avatar.active .menu-title {
        display: none;
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .Header .flex-block .item.active .menu-title {
        display: none;
    }
    .Header .flex-block .item:hover .menu-title {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .Header .menu .dropdown .profile-name {
        display: none;
    }
}
@media only screen and (max-width: 798px) {
    .Header .menu .dropdown .Logout {
        display: flex;
        padding: 0.8rem 1.2rem;
        color: rgba(0, 0, 0, 0.87) !important;
        font-weight: normal !important;
    }
    .Header .menu .Logout {
        display: none;
    }
    .Header .flex-block .item.active .menu-title {
        display: none;
    }
    .Header .flex-block .item:hover .menu-title {
        display: none;
    }
}
@media only screen and (max-width: 750px) {
    .Header .secondary.pointing.menu .toc.item {
        display: block !important;
        margin-right: -1rem;
    }
    .Header .secondary.pointing.menu .menu,
    .Header .ui.fixed.menu {
        display: none !important;
    }
    .Header .secondary.pointing.menu a.item,
    .Header .secondary.pointing.menu div.item {
        display: none !important;
    }
    .Header .item.active ul.submenu {
        display: block;
    }
    .Header .item ul.submenu {
        position: relative !important;
        margin-top: 5px !important;
        margin-bottom: 0px !important;
        top: unset !important;
        line-height: 150% !important;
        background-color: unset !important;
    }
    .Header .item ul.submenu li.submenu__item {
        line-height: 300% !important;
        padding-bottom: 0 !important;
    }
    .Header .item ul.submenu a.submenu__item_link.active {
        font-weight: bold;
    }
    .Header .secondary.pointing.menu .menu.right a.item {
        display: block !important;
    }
    .Header .ui.menu .item > i.icon {
        width: 35px;
        height: 35px;
        font-size: 35px;
        margin: 0;
    }
    .Header .ui.uncover.sidebar.menu .flex-block {
        display: block;
    }
    .Header .flex-block .avatar.item .menu-title,
    .Header .flex-block .avatar.item:hover .menu-title,
    .Header .flex-block .logout.item .menu-title,
    .Header .flex-block .item.active .menu-title,
    .Header .flex-block .logout.item:hover .menu-title,
    .Header .flex-block .item:hover .menu-title {
        display: inline-flex;
    }
    .Header .ui.vertical.menu .menu .item {
        width: 100%;
        padding: 13px 16px;
    }
    .Header .ui.avatar.image {
        width: 22px;
        height: 22px;
    }
    .Header .flex-block .avatar.item .menu-title {
        /*color: rgba(255, 255, 255, 0.9);*/
        font-size: 1rem;
        vertical-align: middle;
    }
    .Header .flex-block .avatar.item:hover .menu-title {
        color: rgba(255, 255, 255, 1);
        font-size: 1rem;
        vertical-align: middle;
    }
}
