.MonthsSelector .search-cross-icon{
    position: absolute;
    z-index: 15;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    right: 2rem;
    opacity: 0.8;
}

