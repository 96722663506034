.CreateRequestForm .ui.small.image{
    margin-bottom: 1rem;
}
.CreateRequestForm .ui.table td .image,
.CreateRequestForm .ui.table td .image img{
    max-width: 150px;
}
.CreateRequestForm button.submit.left {
    margin-left: auto;
    margin-right: 0px;
    display: block;
}
.CreateRequestForm .uploadcare--widget__button {
    cursor: pointer;
}
.CreateRequestForm .ui.message .header:not(.ui) {
    font-size: 24px;
}
