.emergency {

  &.container-with-fixed-header {
    @media (max-width: 976px) {
      padding-left: unset;
    }
  }

  &__wrapper {
    padding: 60px;

    @media (max-width: 1280px) {
      padding: 40px 40px 60px;
    }

    @media (max-width: 500px) {
      padding: 16px 16px 32px;
    }

    @media (max-width: 374px) {
      padding: 8px 8px 16px;
    }
  }

  &__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    color: #3b3b3c;
    margin-bottom: 36px;
    @media (max-width: 480px) {
      font-size: 28px;
      margin-bottom: 20px;
      line-height: 36px;
    }
  }

  &__steps {
    display: grid;
    grid-template-columns: 1fr;
    gap: 26px;
  }

  &__steps-item {
    padding: 40px;
    box-shadow: 0px 5px 14px rgba(81, 81, 81, 0.16);
    border-top: 4px solid #22b374;
    align-items: flex-start;

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #3b3b3c;

    white-space: pre-line;
    line-height: 1.6em;
  }

  &__list {
    list-style: none;
    padding: 0 0 0 26px;
  }

  &__list-item {
    padding: 10px 0;

    &::before {
      position: relative;
      left: -20px;
      content: "\25A0";
      color: #1eb157;
      font-weight: bold;
      display: inline-block;
      width: 6px;
      margin-left: -6px;
    }
  }
}